<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="top-title">
      <titles :title="active == 0 ?'运费金额（近30天）' : '交易金额（近30天）'"/>
      <div class="btn-box">
        <div class="box-section">
          <div class="btn" 
            v-for="(item,index) in itemList" 
            :key="index" 
            :class="{'btn-active':index == active}"
            @click="selectType(index)"
          >{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import titles from './title.vue'
import * as echarts from 'echarts';
export default {
  name:"",
  components: {titles},
  data(){
    return {
      chartLoading:false,
      itemList:['运费金额','交易金额'],
      active: 0,
      myChart: null,
    }
  },
  mounted(){
    this.getEchartData()
  },
  methods:{
    selectType(index){
      if(this.active == index) return;
      this.active = index;
      if (this.myChart) {
        this.myChart.dispose()
        this.myChart = null
      }
      const chart = this.$refs.chart;
      if (chart) {
        this.myChart = echarts.init(chart)
      }
      this.getEchartData(this.active)
    },
    getEchartData(active = 0){
      this.chartLoading = true;
      if(active == 0){
        this.$api.getV2Sixth({
          start_time: this.getThirtyTime()[0],
          end_time: this.getThirtyTime()[1],
          field: 'freight_sum_money'
          // customerId: this.$route.params.id
        }).then(res => {
          if(res.code == 1){
            this.setEchartData(res.data.lists)
          }
        }).finally(() => {
          this.chartLoading = false;
        })
      }else{
        this.$api.getV2Sixth({
          start_time: this.getThirtyTime()[0],
          end_time: this.getThirtyTime()[1],
          field: 'goods_sum_money'
        }).then(res => {
          if(res.code == 1){
            this.setEchartData1(res.data.lists)
          }
        }).finally(() => {
          this.chartLoading = false;
        })
      }
      
      
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      if (chart) {
        this.myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  arr.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：元',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['七日均额', '十五日均额','三十日均额'],
            textStyle:{
              color: '#ffffff'
            }
          },
          series: [
            {
              data: arr.map(ele => {
                return Number(ele.sum_price).toFixed(1) || 0
              }),
              type: 'bar',
              roundCap: true,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#47D1FF' },
                  { offset: 1, color: '#021e54' }
                ]),
                borderRadius: 10
              },
              barWidth: 10,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              label: {
                show: true,
                position: 'top',
                color: '#7DCEF4'
              },
            },
            {
              name: '七日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              data: arr.map(ele => {
                return (Number(ele.day_avg_7)/10000).toFixed(1) || 0
              })
            },
            {
              name: '十五日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              data: arr.map(ele => {
                return (Number(ele.day_avg_15)/10000).toFixed(1) || 0
              })
            },
            {
              name: '三十日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              data: arr.map(ele => {
                return (Number(ele.day_avg_30)/10000).toFixed(1) || 0
              })
            },
          ],
          grid: {
            top:40,
            right: 10,
            bottom:20,
            left:30
          },
        };
        this.myChart.setOption(option);
      }
    },
    setEchartData1(arr){
      const chart = this.$refs.chart;
      if (chart) {
        this.myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  arr.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：万元',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['七日均额', '十五日均额','三十日均额'],
            textStyle:{
              color: '#ffffff'
            }
          },
          series: [
            {
              data: arr.map(ele => {
                return (Number(ele.sum_price)/10000).toFixed(1) || 0
              }),
              type: 'bar',
              roundCap: true,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#47D1FF' },
                  { offset: 1, color: '#021e54' }
                ]),
                borderRadius: 10
              },
              barWidth: 10,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              label: {
                show: true,
                position: 'top',
                color: '#7DCEF4'
              },
            },
            {
              name: '七日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              data: arr.map(ele => {
                return (Number(ele.day_avg_7)/10000).toFixed(1) || 0
              })
            },
            {
              name: '十五日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              data: arr.map(ele => {
                return (Number(ele.day_avg_15)/10000).toFixed(1) || 0
              })
            },
            {
              name: '三十日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              data: arr.map(ele => {
                return (Number(ele.day_avg_30)/10000).toFixed(1) || 0
              })
            },
          ],
          grid: {
            top:40,
            right: 10,
            bottom:20,
            left:30
          },
        };
        this.myChart.setOption(option);
      }
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return [thirtyTime,todayTime]
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-title{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-box{
      flex: 1;
      height: 100%;
      overflow: hidden;
      padding-left: 20px;
      box-sizing: border-box;
      .box-section{
        width: 100%;
        height: 100%;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        .btn{
          display: inline-block;
          height: 28px;
          background: rgba(1, 47, 107, 0.4);
          border: 1px solid #012F6B;
          font-size: 12px;
          color: #63B2FF;
          line-height: 28px;
          padding: 0 10px;
          border-radius: 2px;
          margin-left: 8px;
          cursor: pointer;
        }
        .btn-active{
          background: rgba(110, 178, 255, 0.2);
          border: 1px solid #04DCE2;
          color: #06FCFC;
          box-shadow: inset 0px 0px 14px 0px rgba(2,252,255,0.5);
        }
      }
    }
  }
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="infoWindow">
    <window-bg v-if="info" :color="info.color">
      <div class="window" v-if="!info.is_new">
        <div class="title">{{ info.title }}</div>
        <div class="option" v-if="info.showDeatils" @click="goDetails"><span class="btn">详情</span></div>
        <div class="option" v-if="info.showPrice" @click="openPrice"><span class="btn">报价</span></div>
        <div class="option" v-if="info.showDisable"><span class="btns">暂未入驻</span></div>
        <div class="discount" v-if="info.discount">优惠{{ info.discount }}元</div>
      </div>
      <div class="second" v-if="info.showSecond">
        <div class="dis" v-if="info.ele.distance">直线距离{{ info.ele.distance }}km</div>
        <div class="title">共{{ info.ele.car_number_count }}辆车来过</div>
        <div class="option" @click="openDeriver"><span class="btn">查看</span></div>
      </div>
      <div class="second" v-if="info.showSecond && info.ele.cphList && info.ele.cphList.length">
        <div class="dis" v-if="info.ele.dis">直线距离{{ info.ele.dis }}km</div>
        <div class="title">共{{ info.ele.cphList.length }}辆车来过</div>
        <div class="option" @click="openDeriver"><span class="btn">查看</span></div>
      </div>
      <div class="new" v-if="info.is_new" :style="{color:info.color}">
        <div class="p">车牌号：{{ info.num }}</div>
        <div class="p">于{{ getLocalTime(info.ele.startTime) }}到达{{ info.ele.gaode_info.name }}；</div>
        <div class="p">{{ getLocalTime(info.ele.endTime) }}离开；</div>
        <div class="p">停留{{ (info.ele.parking_time/1000/60).toFixed(0) }}分钟。</div>
      </div>
    </window-bg>
  </div>
</template>
<script>
import windowBg from '@/components/windowBg';
export default {
  name:"infoWindow",
  components:{windowBg},
  data(){
    return {
       info:null,
    }
  },
  methods:{
    getLocalTime(value) {     
      let date = new Date(value);
      let y = date.getFullYear();// 年
      let MM = date.getMonth() + 1;// 月
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();// 日
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();// 时
      h = h < 10 ? ('0' + h) : h;
      let m = date.getMinutes();// 分
      m = m < 10 ? ('0' + m) : m;
      let s = date.getSeconds();// 秒
      s = s < 10 ? ('0' + s) : s;
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;     
    },
    initialize(e){
      console.log(e)
      this.info = e;
    },
    goDetails(){
      this.$emit('goDetails',this.info)
    },
    openPrice(){
      this.$emit('openPrice',this.info)
    },
    openDeriver(){
      this.$emit('openDeriver',this.info)
    },
  }
}
</script>
<style scoped lang='less'>
.window{
  display: flex;
  align-items: center;
  height: 36px;
  .title{
    padding: 6px 12px;
    font-size: 18px;
    color: #00A0E9;
    font-weight: 400;
    white-space:nowrap;
  }
  .option{
    height: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
    position: relative;
    &::before{
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      left: 0;
    }
    span{
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 400;
      white-space:nowrap;
    }
    .btns{
      color: #767676;
    }
  }
  .discount{
    padding: 6px 12px;
    font-size: 18px;
    color: #54FFD9;
    font-weight: 400;
    white-space: nowrap;
  }
}
.second{
  display: flex;
  align-items: center;
  height: 36px;
  .dis{
    padding: 6px 12px;
    font-size: 18px;
    color: #00A0E9;
    font-weight: 400;
    white-space:nowrap;
  }
  .title{
    padding: 6px 12px;
    font-size: 18px;
    color: #54FFD9;
    font-weight: 400;
    white-space:nowrap;
  }
  .option{
    height: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
    position: relative;
    &::before{
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      left: 0;
    }
    span{
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 400;
      white-space:nowrap;
    }
    .btns{
      color: #767676;
    }
  }
}
.new{
  padding: 0 10px;
}
</style>
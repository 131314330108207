import Vue from 'vue'
import VueRouter from 'vue-router'

// import Index from '../views/Index.vue'
import Index from '../views/Data.vue'
import Detail from '../views/Detail.vue'
import Test from '../views/Test.vue'
import Chart from '../views/chart.vue'
import Login from '../views/Login.vue'
import Map from '../views/Map.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/chart',
    name: 'chart',
    component: Chart
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    redirect:'/map/index',
    children:[
      {
        path: "index",
        name: "mapIndex",
        component: () => import("../views/maps/index.vue"),
      },
      {
        path: "mine/:id",
        name: "mapMine",
        component: () => import("../views/maps/mine.vue"),
      },
      {
        path: "concrete/:id",
        name: "mapConcrete",
        component: () => import("../views/maps/concrete.vue"),
      },
      {
        path: "driver/:id",
        name: "mapDirver",
        component: () => import("../views/maps/driver.vue"),
      },
      {
        path: "party/:id",
        name: "mapParty",
        component: () => import("../views/maps/party.vue"),
      },
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="data-title" :style="{width:`${width + 30}px`}" @click="cityShow = false">
    <div class="bar"></div>
    <div class="text" v-if="dropdown" @click.stop="cityShow = !cityShow">
        <span>{{ title || '无' }}</span>
        <i class="el-icon-caret-bottom"></i>
        <transition name="el-zoom-in-top">
          <div class="city-content" v-if="cityShow">
            <vue-scroll>
              <div class="list-box">
                <div class="list" v-for="(item,index) in cityList" :key="index" @click="checkItem(index)">
                  {{ item.name }} - {{ item.type_name }}
                </div>
              </div>
            </vue-scroll>
          </div>
        </transition>
      </div>
    <!-- <el-dropdown trigger="click" v-if="dropdown"  @command="checkItem">
      <div class="text">
        <span>{{ title || '无' }}</span>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(item,index) in cityList" :key="index" :command="index">
          {{ item.name }} - {{ item.type_name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
    <div class="text" v-else>
        <span>{{ title }}</span>
      </div>
    <dv-decoration-2 style="height:3px;" :style="{width:`${width}px`}" />
  </div>
</template>
<script>
export default {
  name:"data-title",
  props:{
    title:{
      default:''
    },
    width:{
      type: Number,
      default: 200
    },
    dropdown:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      cityList:[],
      city:null,
      cityShow:false
    }
  },
  mounted(){
    if(this.dropdown){
      this.getCity()
    }
  },
  methods:{
    getCity(){
      this.$api.getNewCompanyCityList().then(res => {
        if(res.code == 1){
          res.data.lists.factory_city.forEach(element => {
            element.type = 1;
            element.type_name = '矿山'
          });
          this.cityList.push(...res.data.lists.factory_city)
          res.data.lists.commercial_concrete_city.forEach(element => {
            element.type = 2;
            element.type_name = '商砼'
          });
          this.cityList.push(...res.data.lists.commercial_concrete_city)
          this.city = this.cityList[0] || null;
          this.$emit('checkCity',this.city)
        }
      })
    },
    checkItem(index){
      this.city = this.cityList[index] || null;
      this.$emit('checkCity',this.city)
    }
  }
}
</script>
<style scoped lang='less'>
.data-title{
  height: 30px;
  padding-left: 30px;
  position: relative;
  .bar{
    width: 26px;
    height: 20px;
    background: url('../assets/image/data/arrow.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 5px;
  }
  .text{
    position: relative;
    span{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 25px;
      text-shadow: 0px 1px 3px #A8CCFA;
    }
    .el-icon-caret-bottom{
      color: #10c9e1;
      margin-left: 10px;
    }
    .city-content{
      width: 100%;
      height: 200px;
      background-color: #fff;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: 10000;
      border-radius: 4px;
      &::before{
        content: '';
        display: block;
        border: 6px solid transparent;
        border-bottom-color: #fff;
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -3px;
      }
      .list-box{
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 12px 0;
        box-sizing: border-box;
        .list{
          list-style: none;
          line-height: 36px;
          padding: 0 20px;
          margin: 0;
          font-size: 14px;
          color: #606266;
          cursor: pointer;
          outline: none;
          &:hover{
            background-color: #ecf5ff;
            color: #66b1ff;
          }
        }
      }
    }
  }
}
.data-title /deep/.el-dropdown-menu{
  max-height: 300px;
  overflow: auto;
}
</style>
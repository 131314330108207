<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="title-box">
      <data-title title="分公司交易数据" :width="150"></data-title>
      <div class="time">
        <el-date-picker
          v-model="time"
          type="daterange"
          value-format="yyyy-MM-dd"
          @change="timeChange">
        </el-date-picker>
        <div class="time-ele">
          <i class="el-icon-time"></i>
          {{ time[0] }}
        </div>
        <span>-</span>
        <div class="time-ele">
          <i class="el-icon-time"></i>
          {{ time[1] }}
        </div>
      </div>
    </div>
    <div class="chart-title">
      <div class="ele">贸易金额(万元)</div>
      <div class="ele">运输金额(万元)</div>
    </div>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import * as echarts from 'echarts';
export default {
  name:"data-card-1",
  components:{dataTitle},
  data(){
    return {
      chartLoading:false,
      time:[]
    }
  },
  mounted(){
    this.time = [this.getThirtyTime().thirtyTime,this.getThirtyTime().todayTime];
    this.getEchartData()
  },
  methods:{
    getEchartData(){
      this.chartLoading = true;
      this.$api.getNewCompanyTransactionData({
        start_time: this.time[0],
        end_time: this.time[1]
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(res.data.lists)
        }
      }).finally(() => {
        this.chartLoading = false;
      })
    },
    timeChange(){
      this.getEchartData();
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = echarts.init(chart)
        let arr1 = arr.map(ele => {
          return Number(ele.freight_total_price)
        })
        let arr2 = arr.map(ele => {
          return -Number(ele.material_total_price)
        })
        let total = arr1.concat(arr2.map(ele => {
          return -ele
        }))
        let _max = Math.ceil(Math.max(...total)/ 10) * 10
        const option = {
          tooltip : {
            trigger: 'axis',
            axisPointer : {       
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            top: 10,
            left: 20,
            right: 20,
            bottom: 30,
            containLabel: true
          },
          xAxis : [
            {
              type : 'value',
              splitLine:{
                lineStyle:{
                  width: 0.5,
                  type:'dashed',
                  color:'#1B407F',
                }
              },
              axisLabel:{
                show:true,
                color:'#B2E6FF',
                formatter:function(value){
                  if (value<0) {
                    return -value;
                  }else{
                    return value
                  }
                }
              },
              axisLine:{
                show: true,
                lineStyle:{
                  width: 0.5,
                  type:'dashed',
                  color:'#1B407F',
                }
              },
              axisTick:{
                show: false
              },
              max: _max,
              min: -_max,
            }
          ],
          yAxis : [
            {
              show:true,
              type : 'category',
              position:'left',
              axisLabel:{
                color:'#B2E6FF',
              },
              axisTick:{
                show: false
              },
              axisLine:{
                show: true,
                lineStyle:{
                  width: 1,
                  type:'solid',
                  color:'#1B407F',
                }
              },
              data : arr.map(ele => {
                return ele.commercial_concrete_city_code
              })
            }
          ],
          series : [
            {
              name:'贸易金额',
              type:'bar',
              stack: '总量',
              barWidth: 12,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#D0E450' },
                  { offset: 1, color: '#1450AB' }
                ]),
                borderRadius: 10
              },
              tooltip: {
                valueFormatter: function (value) {
                  if(-value > 0){
                    return -value + '万元';
                  }else{
                    return -value;
                  }
                }
              },
              data: arr2
            },
            {
              name:'运输金额',
              type:'bar',
              stack: '总量',
              barWidth: 12,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#1450AB' },
                  { offset: 1, color: '#07B860' }
                ]),
                borderRadius: 10
              },
              tooltip: {
                valueFormatter: function (value) {
                  if(value > 0){
                    return value + '万元';
                  }else{
                    return value;
                  }
                }
              },
              data: arr1
            }
          ]
        };
        myChart.setOption(option);
      }
    },
    getThirtyTime(){
      let today = new Date();
      let today_m = today.getMonth() + 1;
      let today_d = today.getDate();
      let todayTime = `${today.getFullYear()}-${today_m < 10 ? '0' + today_m : today_m}-${today_d < 10 ? '0' + today_d : today_d}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirty_m = thirty.getMonth() + 1;
      let thirty_d = thirty.getDate();
      let thirtyTime = `${thirty.getFullYear()}-${thirty_m < 10 ? '0' + thirty_m : thirty_m}-${thirty_d < 10 ? '0' + thirty_d : thirty_d}`
      return {
        todayTime,
        thirtyTime
      }
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title-box{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .time{
      width: 250px;
      display: flex;
      align-items: center;
      position: relative;
      .time-ele{
        height: 28px;
        background: rgba(1, 47, 107, 0.4);
        border: 1px solid #012F6B;
        font-size: 14px;
        color: #63B2FF;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 2px;
        cursor: pointer;
        i{
          margin-right: 5px;
        }
      }
      span{
        margin: 0 6px;
        font-size: 18px;
        color: #63B2FF;
      }
    }
    .time /deep/.el-date-editor{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  .chart-title{
    box-sizing: border-box;
    width: 100%;
    padding-left: calc(20px + 3em);
    display: flex;
    align-items: center;
    margin: 16px 0 12px 0;
    .ele{
      flex: 1;
      font-size: 15px;
      color: #fff;
      line-height: 22px;
      text-align: center;
    }
  }
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="headers">
    <div class="bg bg-left"></div>
    <div class="bg bg-right"></div>
    <div class="section">
      <div class="left btn" v-if="isback" @click="back">返回</div>
      <div class="title">大数据监管分析平台</div>
      <div class="right">
        <times></times>
      </div>
    </div>
  </div>
</template>
<script>
import times from './times.vue'
export default {
  name:"headers",
  components:{times},
  props:{
    isback:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
       
    }
  },
  methods:{
    back(){
      if(this.$route.path == '/'){
        window.location.href="javascript:history.go(-1)"
      }else{
        this.$router.go(-1);
      }
    }
  }
}
</script>
<style scoped lang='less'>
.headers{
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1999;
  .bg{
    flex: 1;
    height: 100%;
    background: red;
  }
  .bg-left{
    background: linear-gradient(270deg, #0F4497 0%, #142845 100%);
  }
  .bg-right{
    background: linear-gradient(90deg, #0F4497 0%, #142845 100%);
  }
  .section{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    .left{
      height: 100%;
      padding: 0 30px;
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #FFFFFF;
      font-weight: 400;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
    .title{
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 72px;
      font-size: 36px;
      color: #57F7F6;
      font-weight: 500;
    }
    .right{
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
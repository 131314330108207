<template>
  <div class="maps">
    <headers :isback="isback"></headers>
    <transition :name="transitionName">
      <router-view class="view"/>
    </transition>
  </div>
</template>
<script>
export default {
  name:"maps",
  data(){
    return {
      isback:true,
      transitionName:'',  
    }
  },
  watch: {//使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if(to.meta.index > from.meta.index){
	      //设置动画名称
        this.transitionName = 'slide-left';
      }else{
        this.transitionName = 'slide-right';
      }
    }
  },
}
</script>
<style scoped lang='less'>
.maps{
  width: 100%;
  height: 100%;
  background: #142845;
}
.view{
  height: calc(100% - 72px);
  background: #142845;
  overflow: hidden;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all .3s ease;
  position: absolute;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(0, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(0, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
}
</style>
<template>
  <div class="times">
    <div class="time">{{ time }}</div>
    <div class="date">
      <div class="date-top">{{ week }}</div>
      <div class="date-btm">{{ date }}</div>
    </div>
    <i class="el-icon-switch-button" @click="logout"></i>
  </div>
</template>
<script>
export default {
  name:"times",
  data(){
    return {
      date:'',
      time:'',
      week:''
    }
  },
  mounted() {
    this.currentTime();
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate);
    }
  },
  methods: {
    logout(){
      this.$store.commit('TOKEN',null)
      this.$store.commit('USERINFO',null)
      this.$router.replace('/login')
    },
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.week = weekArr[week];
      this.date = `${year}.${month}.${day}`;
      this.time = `${hour}:${minute}:${second}`;
      // this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    }
  }
  
}
</script>
<style scoped lang='less'>
.times{
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  .time{
    min-width: 144px;
    font-size: 34px;
    line-height: 48px;
    color: #FFFFFF;
    font-weight: 400;
  }
  .date{
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    font-weight: 400;
    padding-bottom: 6px;
  }
  i{
      margin-left: 10px;
      color: #00cadb;
      font-size: 22px;
      cursor: pointer;
    }
}
</style>
<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <data-title title="地区分布" :width="80"></data-title>
    <div class="cahrt-box">
      <div class="charts" ref="chart"></div>
      <div class="charts" ref="chart1"></div>
    </div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import * as echarts from 'echarts';
export default {
  name:"chart-2",
  components:{dataTitle},
  data(){
    return {
      chartLoading:false,
    }
  },
  mounted() {
    this.getEchartData()
  },
  methods: {
    getEchartData(){
      this.chartLoading = true;
      this.$api.getChartRegions({
        start_time: '2022-01-01 00:00:00',
        end_time: '2022-09-01 00:00:00'
        // factory_id: ''
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(res.data.info)
        }
      }).finally(() => {
        setTimeout(() => {
          this.chartLoading = false;
        },200)
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      const chart1 = this.$refs.chart1;
      if (chart) {
        this.myChart = echarts.init(chart)
        this.myChart1 = echarts.init(chart1)
        let _this = this
        const option = {
          title: {
            text: '城市分布',
            left: 'center',
            textStyle:{
              color: '#fff'
            }
          },
          tooltip: {
            trigger: 'item',
            valueFormatter: (value) => {
              return value + '吨'
            }
          },
          legend: {
            left: 'center',
            bottom:'2%',
            textStyle:{
              color: '#fff'
            }
          },
          series: [
            {
              type: 'pie',
              radius: ['30%', '60%'],
              data: arr.map(ele => {
                return {
                  value: ele.weight,
                  name: ele.name
                }
              })
            }
          ]
        };
        const option1 = {
          title: {
            text: arr[0].name,
            left: 'center',
            textStyle:{
              color: '#fff'
            }
          },
          tooltip: {
            trigger: 'item',
            valueFormatter: (value) => {
              return value + '吨'
            }
          },
          legend: {
            left: 'center',
            bottom:'2%',
            textStyle:{
              color: '#fff'
            }
          },
          series: [
            {
              type: 'pie',
              radius: ['30%', '60%'],
              data: arr[0].lists.map(ele => {
                return {
                  value: ele.weight,
                  name: ele.name
                }
              })
            }
          ]
        }
        this.myChart.on('mouseover', function (event) {
          let item = arr.find(ele => {
            return ele.name == event.name
          })
          if(item){
            _this.myChart1.setOption({
              title: {
                text: event.name,
                left: 'center',
                textStyle:{
                  color: '#fff'
                }
              },
              series: [
                {
                  type: 'pie',
                  radius: ['30%', '60%'],
                  data: item.lists.map(ele => {
                    return {
                      value: ele.weight,
                      name: ele.name
                    }
                  })
                }
              ]
            });
          }
        });
        this.myChart.setOption(option);
        this.myChart1.setOption(option1);
      }
    },
  },
  
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .cahrt-box{
    flex: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
  }
  .charts{
    height: 100%;
    flex: 1;
  }
}
</style>
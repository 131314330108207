<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <data-title title="分公司排行" :width="100"></data-title>
    <div class="box">
      <swiper ref="mySwiper" :options="swiperOptions" style="height:100%">
        <swiper-slide class="swiper-slide" v-for="(item,index) in topList" :key="index">
          <div class="list" @click.stop="openOrderList(item)">
            <div class="num" :class="{num1:index == 0,num2:index == 1,num3:index == 2}">{{ index + 1 }}</div>
            <div class="name">
              {{ item.name }}
              <span>
                {{ (Number(item.material_total_price)/10000).toFixed(2) }}万元
                <i class="el-icon-arrow-right" style="margin-left: 10px" @click.stop="openDetails(item)"></i>
              </span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <orderList :show="showOrder" :query="activeItem"></orderList>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import orderList from '../order-list.vue'
export default {
  name:"data-card-4",
  components:{
    dataTitle,
    swiper,
    swiperSlide,
    orderList
  },
  data(){
    return {
      chartLoading:false,
      swiperOptions: {
        direction:'vertical',
        slidesPerView:5,
        autoplay:{
          delay: 2000
        },
      },
      topList:[],
      showOrder: false,
      activeItem: null
    }
  },
  mounted(){
    this.getTopList();
    document.onclick = () => {
      this.showOrder = false;
    }
  },
  methods:{
    getTopList(){
      this.chartLoading = true
      this.$api.getNewCompanyRank({
        // type:2,
        // page:1,
        // size:10
      }).then(res => {
        if(res.code == 1){
          this.topList = [...res.data.lists];
          setTimeout(()=>{
            this.swiperOptions.loopAdditionalSlides = 5;
            this.swiperOptions.loop = true; 
          },500) 
        }
      }).finally(() => {
        setTimeout(()=>{
          this.chartLoading = false;
        },500)
      })
    },
    openOrderList(item){
      this.activeItem = {...item};
      this.showOrder = true;
    },
    openDetails(item){
      this.$store.commit('WALLID',item.id)
      this.$router.push('/detail')
      // console.log(item)
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .box{
    width: 100%;
    flex: 1;
    overflow: hidden;
  }
}
.swiper-slide{
  width: 100%;
  height: 34px;
  box-sizing: border-box;
  padding: 6px 0;
  .list{
    width: 100%;
    height: 100%;
    background: rgba(2, 69, 142, 0.25);
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    .num{
      width: 22px;
      height: 22px;
      background: url('../../assets/image/data/4.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-top: 1px;
      font-size: 12px;
      color: #1782D7;
    }
    .num1{
      background: url('../../assets/image/data/1.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
    .num2{
      background: url('../../assets/image/data/2.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
    .num3{
      background: url('../../assets/image/data/3.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
    .name{
      margin-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      font-size: 16px;
      color: #07E1FF;
      span{
        color: #fff;
      }
    }
    
  }
}
</style>
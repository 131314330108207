<template>
  <div class="section">
    <div class="ele" v-loading="chart1Loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <div class="title">贸易金额（近30天）</div>
      <div class="charts" ref="chart1"></div>
    </div>
    <div class="ele" v-loading="chart2Loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <div class="title">运输金额（近30天）</div>
      <div class="charts" ref="chart2"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data(){
    return {
       chart1Loading:false,
       chart2Loading:false
    }
  },
  mounted(){
    this.getEchart1Data();
    this.getEchart2Data();
  },
  methods:{
    getEchart1Data(){
      this.chart1Loading = true;
      this.$api.getLeftData({
        field: 'goods_sum_money',
        start_time: this.getThirtyTime().thirtyTime,
        end_time: this.getThirtyTime().todayTime
      }).then(res => {
        if(res.code == 1){
          this.setEchart1Data(res.data.lists)
        }
      }).finally(() => {
        this.chart1Loading = false;
      })
      
      
    },
    setEchart1Data(arr) {
      const chart = this.$refs.chart1;
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: arr.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：万/元',
            nameLocation: 'end',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          dataZoom: [
            {
              type: "inside",//inside鼠标缩放
              show: true,
              filterMode: 'none',
              xAxisIndex: [0],
            }
          ],
          series: [
            {
              data: arr.map(ele => {
                return Number(ele.goods_sum_moneys) || 0
              }),
              type: 'line',
              showSymbol: false,
              smooth: true,
              color:'#EFB102',
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(239, 177, 2, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(239, 177, 2, 0.1)'
                  }
                ])
              }
            }
          ],
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top:30,
            right: 20,
            bottom:30,
            left:30
          },
        };
        myChart.setOption(option);
      }
    },
    getEchart2Data(){
      this.chart2Loading = true;
      this.$api.getLeftData({
        field: 'freight_sum_money',
        start_time: this.getThirtyTime().thirtyTime,
        end_time: this.getThirtyTime().todayTime
      }).then(res => {
        if(res.code == 1){
          this.setEchart2Data(res.data.lists)
        }
      }).finally(() => {
        this.chart2Loading = false;
      })
    },
    setEchart2Data(arr){
      const chart = this.$refs.chart2;
      if (chart) {
        console.log(echarts)
        const myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data:  arr.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：万/元',
            nameLocation: 'end',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          dataZoom: [
            {
              type: "inside",//inside鼠标缩放
              show: true,
              filterMode: 'none',
              xAxisIndex: [0],
            }
          ],
          series: [
            {
              data: arr.map(ele => {
                return Number(ele.freight_sum_moneys) || 0
              }),
              type: 'line',
              showSymbol: false,
              smooth: true,
              color:'#CA2CFA',
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(202, 44, 250, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(202, 44, 250, 0.1)'
                  }
                ])
              }
            }
          ],
          grid: {
            top:30,
            right: 20,
            bottom:30,
            left:30
          },
        };
        myChart.setOption(option);
      }
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  },

}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 30px;
  box-sizing: border-box;
  .ele{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .charts{
      width: 100%;
      flex: 1;
    }
  }
}
.title{
  width: 220px;
  height: 32px;
  background: url('../../assets/image/data/title-bg.png') no-repeat;
  background-size: 100% 100%;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  padding-left: 36px;
  margin-top: 10px;
}
</style>
<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="top-title">
      <titles title="矿山销售排名"/>
      <div class="btn-box">
        <div class="box-section">
          <div class="btn" 
            v-for="(item,index) in itemList" 
            :key="index" 
            :class="{'btn-active':index == active}"
            @click="selectType(index)"
          >{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="list-box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <div class="list-center">
            <div class="left">
              <span>{{ index + 1 }}</span>
              <div class="text" v-if="item.name.length < 7">{{ item.name }}</div>
              <el-tooltip v-else :content="item.name" placement="top">
                <div class="text">{{ item.name }}</div>
              </el-tooltip>
            </div>
            <div class="bar">
              <div class="line" :style="{width: `${Number(item.total_money)/ Number(list[0].total_money) * 100}%`}">
                <div class="tag"></div>
              </div>
            </div>
            <div class="right">
              <span v-if="Number(item.total_money) < 10000">{{ item.total_money }}元</span>
              <span v-else>{{ (Number(item.total_money)/10000).toFixed(2) }}万元</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import titles from './title.vue'
export default {
  name:"",
  components: {titles},
  data(){
    return {
      chartLoading:false,
      itemList:['近一周排名','近一月排名'],
      active: 0,
      list: []
    }
  },
  mounted(){
    this.getEchartData(this.active)
  },
  methods:{
    selectType(index){
      if(this.active == index) return;
      this.active = index;
      this.getEchartData(this.active)
    },
    getEchartData(type){
      let param = {}
      if(type == 0){
        param.start_time = this.getTimeString()[0]
        param.end_time = this.getTimeString()[1]
      }else{
        param.start_time = this.getTimeString(30)[0]
        param.end_time = this.getTimeString(30)[1]
      }
      this.chartLoading = true;
      this.$api.getV2Second(param).then(res => {
        if(res.code == 1){
          this.list = [...res.data.lists.slice(0,5)]
        }
      }).finally(() => {
        this.chartLoading = false;
      })
    },
    getTimeString(num = 7){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * num)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return [thirtyTime,todayTime]
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-title{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-box{
      flex: 1;
      height: 100%;
      overflow: hidden;
      padding-left: 20px;
      box-sizing: border-box;
      .box-section{
        width: 100%;
        height: 100%;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        .btn{
          display: inline-block;
          height: 28px;
          background: rgba(1, 47, 107, 0.4);
          border: 1px solid #012F6B;
          font-size: 12px;
          color: #63B2FF;
          line-height: 28px;
          padding: 0 10px;
          border-radius: 2px;
          margin-left: 8px;
          cursor: pointer;
        }
        .btn-active{
          background: rgba(110, 178, 255, 0.2);
          border: 1px solid #04DCE2;
          color: #06FCFC;
          box-shadow: inset 0px 0px 14px 0px rgba(2,252,255,0.5);
        }
      }
    }
  }
  .list-box{
    width: 100%;
    flex: 1;
    overflow: hidden;
    padding-top: 12px;
    .list{
      width: 100%;
      height: 20%;
      min-height: 40px;
      display: flex;
      align-items: center;
      .list-center{
        width: 100%;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          width: 145px;
          height: 100%;
          padding-left: 26px;
          position: relative;
          box-sizing: border-box;
          
          span{
            position: absolute;
            left: 0;
            top: -8px;
            width: 26px;
            text-align: center;
            font-size: 22px;
            font-family: Source-KeynoteartHans-Regular, Source-KeynoteartHans;
            color: #FFFFFF;
            line-height: 27px;
            font-style: italic;
            font-weight: 600;
          }
          .text{
            width: 100%;
            height: 100%;
            line-height: 28px;
            font-size: 18px;
            color: #FFFFFF;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .bar{
          flex: 1;
          margin: 10px 0;
          height: 8px;
          .line{
            width: 0;
            height: 100%;
            background: linear-gradient(270deg, #0663E7 0%, #00153F 100%);
            position: relative;
            transition: all 1s linear;
            .tag{
              width: 12px;
              height: 12px;
              background: #FFFFFF;
              box-shadow: 0px 0px 8px 1px rgba(255,255,255,0.5);
              filter: blur(0px);
              position: absolute;
              right: -5px;
              top: -2px;
              border-radius: 50%;
            }
          }
        }
        .right{
          width: 100px;
          text-align: right;
          font-size: 14px;
          color: #FFFFFF;
          font-weight: 500;
          line-height: 20px;
        }
      }
      &:nth-child(1){
        .list-center{
          .left{
            background: linear-gradient(90deg, #0342FB 0%, #001842 80%);
          }
        }
      }
      &:nth-child(2){
        .list-center{
          .left{
            background: linear-gradient(90deg, #3B68ED 0%, #001842 80%);
          }
        }
      }
      &:nth-child(3){
        .list-center{
          .left{
            background: linear-gradient(90deg, #26A9F0 0%, #001842 80%);
          }
        }
      }
    }

  }
}
</style>
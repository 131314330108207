<template>
  <transition name="slide-fade">
    <div class="order-list" v-show="show" @click.stop v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <data-card>
        <div class="title" v-if="query">{{ query.name || '今日下单' }}</div>
        <div class="order-title">
          <span>订单列表</span>
          <div class="time">
            <el-date-picker
              v-model="time"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="timeChange">
            </el-date-picker>
            <div class="time-ele">
              <i class="el-icon-time"></i>
              <div class="ele">{{ time[0] || '开始时间' }}</div>
              <span class="bar">-</span>
              <div class="ele">{{ time[1] || '结束时间' }}</div>
              <div class="close" v-if="time[0] && time[1]" @click="time = []">
                <i class="el-icon-circle-close"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="list-box">
          <div class="list" v-for="(item,index) in list" :key="index">
            <div class="no">订单编号：{{ item.order_no }}</div>
            <div class="desc">
              <div class="ele">商品名称：{{ item.factory_goods_name || '-' }}}</div>
              <div class="ele">商品单价：元</div>
              <div class="ele">总吨数：{{ item.factory_order_net_weight }}吨</div>
              <div class="ele">总价格：{{ item.total_money }}元</div>
              <div class="item">起始地：{{ item.send_address_name }}</div>
              <div class="item">目的地：-</div>
            </div>
            <div class="btm">
              <span class="time">{{ item.created_at }}</span>
              <div class="btn">详情</div>
            </div>
          </div>
          <div class="more" v-if="list && total > list.length" @click="getMore">
            <span v-if="!pageLoad">加载更多</span>
            <span v-else><i class="el-icon-loading"></i> 加载中</span>
          </div>          
        </div>
      </data-card>
    </div>
  </transition>
</template>
<script>
import dataCard from '../components/data-crad.vue'
export default {
  name:"order-list",
  components:{ dataCard },
  props:{
    show: {
      default: false,
      type: Boolean
    },
    query:{
      default: null
    }
  },
  watch:{
    show(val){
      if(val){
        this.getList();
      }
    }
  },
  data(){
    return {
      time:[],
      loading:false,
      page:1,
      total:0,
      list:null,
      pageLoad:false
    }
  },
  methods:{
    timeChange(){
      
    },
    getList(){
      this.loading = true;
      this.pageLoad = true;
      this.$api.getOrderList({
        sub_company_id: this.query.id,
        start_time: this.time[0] || '',
        end_time: this.time[1] || '',
        page: this.page,
        size: 20
      }).then(res => {
        if(this.page == 1) this.list = [];
        this.total = res.data.count;
        this.list.push(...res.data.lists)
      }).finally(()=>{
        this.loading = false;
        this.pageLoad = false;
      })
    },
    getMore(){
      this.page ++;
      this.getList();
    }
  }
}
</script>
<style scoped lang='less'>
.order-list{
  width: 380px;
  height: calc(100% - 90px - 26px);
  min-height: 500px;
  background: rgba(43, 86, 153, 0.85);
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 100000;
  .title{
    width: 100%;
    height: 32px;
    font-size: 18px;
    text-align: center;
    color: #b8e5fa;
    font-weight: 700;
  }
  .order-title{
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    span{
      font-size: 16px;
      font-weight: bold;
      color: #b8e5fa;
      margin-right: 12px;
    }
    .time{
      overflow: hidden;
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      .time-ele{
        flex: 1;
        height: 30px;
        background: rgba(1, 47, 107, 0.4);
        border: 1px solid #012F6B;
        font-size: 14px;
        color: #b8e5fa;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 2px;
        padding-right: 40px;
        cursor: pointer;
        position: relative;
        .el-icon-time{
          margin-right: 5px;
        }
        .ele{
          flex: 1;
          text-align: center;
        }
        .close{
          width: 40px;
          height: 100%;
          text-align: center;
          line-height: 32px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .bar{
        margin: 0 6px;
        font-size: 18px;
        color: #63B2FF;
      }
    }
    .time /deep/.el-date-editor{
      position: absolute;
      width: calc(100% - 40px);
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 10;
    }
  }
  .list-box{
    width: 100%;
    height: calc(100% - 76px);
    overflow-y: auto;
    &::-webkit-scrollbar { 
      display: none;
    }
    .list{
      box-sizing: border-box;
      width: 100%;
      min-height: 200px;
      margin-bottom: 12px;
      background: #023a70;
      border: 2px solid #0184bf;
      padding: 0 12px;
      .no{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        line-height: 38px;
        text-align: center;
      }
      .desc{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #214c6e;
        border-bottom: 1px solid #214c6e;
        padding: 5px 0;
        .ele{
          width: 50%;
          margin: 5px 0;
          font-size: 12px;
          color: #fff;
        }
        .item{
          width: 100%;
          margin: 7px 0;
          font-size: 12px;
          color: #fff;
        }
      }
      .btm{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time{
          font-size: 12px;
          color: #fff;
        }
        .btn{
          height: 24px;
          padding: 0 12px;
          border-radius: 12px;
          line-height: 24px;
          background: #025bcb;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .more{
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #b8e5fa;
      cursor: pointer;
    }
  }
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<template>
  <div class="section" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="top">
      <img src="../../assets/image/data/center-bg.png" alt="">
      <div class="top-title">交易数据</div>
      <div class="ele" v-for="(item,index) in list" :key="index" :class="`ele-${index}`">
        <div class="price">
          <p>{{ item.goods_unit_price }}元</p>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="btm">
      <div class="title">交易占比（单位：吨）</div>
      <div class="list-box">
        <div class="list" v-for="(item,index) in list" :key="index">
          <img src="../../assets/image/data/center-btm-icon.png" alt="">
          <span>{{ item.name }}</span>
          <p>{{ item.weight }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"",
  data(){
    return {
      loading:false,
      list:[],
    }
  },
  mounted(){
    this.getList()
  },
  methods:{

    getList(){
      this.loading = true;
      this.$api.mineconSaleRank({
        page:1,
        size:5
      }).then(res => {
        if(res.code == 1){
          this.list = [...res.data.lists]
        }
      }).finally(() => {
        this.loading = false;
      })
    },
  }
  
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // img{
    //   animation:fadenum 5s infinite;
    // }
    // @keyframes fadenum{
    //   100%{transform:rotateY(360deg);}
    // }
    .top-title{
      font-size: 30px;
      font-weight: 600;
      color: #A0E1FF;
      position: absolute;
      left: 415px;
      top: 160px;
    }
    .ele{
      width: 80px;
      height: 178px;
      background: url('../../assets/image/data/center-icon-bg.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      .price{
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
          margin: 0;
          font-size: 20px;
          line-height: 28px;
          color: #A0E1FF;
          font-weight: 500;
        }
        span{
          font-size: 16px;
          line-height: 22px;
          color: #A0E1FF;
        }
      }
    }
    .ele-0{
      left: 150px;
      top: 80px;
    }
    .ele-1{
      left: 0;
      top: 240px;
    }
    .ele-2{
      right: 160px;
      top: 80px;
    }
    .ele-3{
      left: 260px;
      bottom: -20px;
    }
    .ele-4{
      right: 260px;
      bottom: -30px;
    }
  }
  .btm{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .list-box{
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      .list{
        height: 100%;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 43px;
          height: 50px;
        }
        span{
          font-size: 16px;
          line-height: 22px;
          color: #FFFFFF;
        }
        p{
          margin: 0;
          font-size: 24px;
          line-height: 34px;
          color: #FFFFFF;
          font-weight: 500;
        }
      }
    }
  }
}
.title{
  width: 220px;
  height: 32px;
  background: url('../../assets/image/data/title-bg.png') no-repeat;
  background-size: 100% 100%;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  padding-left: 36px;
  margin-top: 20px;
}
</style>
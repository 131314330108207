<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <data-title title="分公司运费价格（近30天）" :width="220"></data-title>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import * as echarts from 'echarts';
export default {
  name:"data-card-1",
   components:{dataTitle},
  data(){
    return {
       chartLoading:false,
    }
  },
  mounted(){
    this.getEchartData()
  },
  methods:{
    getEchartData(){
      this.chartLoading = true;
      this.$api.getFreightHistory({
        start_time: this.getThirtyTime().thirtyTime,
        end_time: this.getThirtyTime().todayTime
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(res.data.lists)
        }
      }).finally(() => {
        this.chartLoading = false;
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  arr.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          series: [
            {
              data: arr.map(ele => {
                return Number(ele.freight_unit_price) || 0
              }),
              type: 'bar',
              roundCap: true,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#47D1FF' },
                  { offset: 1, color: '#225BFF' }
                ]),
                borderRadius: 10
              },
              barWidth: 10,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              label: {
                show: true,
                position: 'top',
                color: '#7DCEF4'
              },
            }
          ],
          grid: {
            top:20,
            right: 10,
            bottom:20,
            left:30
          },
        };
        myChart.setOption(option);
      }
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="windowBg" :style="{border:`1px solid ${color}`,'box-shadow':`0 0 8px 0 ${color} inset`}">
    <div class="bar bar-1"></div>
    <div class="bar bar-2"></div>
    <div class="bar bar-3"></div>
    <div class="bar bar-4"></div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name:"",
  data(){
    return {
       
    }
  },
  props:{
    color:{
      type:String,
      default:'#767676'
    }
  }
}
</script>
<style scoped lang='less'>
.windowBg{
  background: #12223B;
  min-width: 100px;
  position: relative;
  padding: 6px 0;
  .bar{
    width: 10px;
    height: 8px;
    position: absolute;
  }
  .bar-1{
    left: -1px;
    top: -1px;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #EBE700;
      position: absolute;
      top: 0;
      left: 0;
    }
     &::after{
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: #EBE700;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .bar-2{
    right: -1px;
    top: -1px;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #EBE700;
      position: absolute;
      top: 0;
      right: 0;
    }
     &::after{
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: #EBE700;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .bar-3{
    right: -1px;
    bottom: -1px;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #EBE700;
      position: absolute;
      bottom: 0;
      right: 0;
    }
     &::after{
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: #EBE700;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .bar-4{
    left: -1px;
    bottom: -1px;
     &::before{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #EBE700;
      position: absolute;
      bottom: 0;
      left: 0;
    }
     &::after{
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: #EBE700;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
<template>
  <div class="title">
    <div class="bar"></div>
    <div class="text">{{ title }}</div>
  </div>
</template>
<script>
export default {
  name:"",
  props:{
    title:{
      default:''
    }
  },
  data(){
    return {
       
    }
  },
}
</script>
<style scoped lang='less'>
.title{
  height: 30px;
  padding-left: 30px;
  position: relative;
  .bar{
    width: 26px;
    height: 20px;
    background: url('../../assets/image/data/arrow.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 5px;
  }
  .text{
    font-size: 18px;
    line-height: 30px;
    background-image:-webkit-linear-gradient(bottom,#FFD264,#FFA534); 
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
  }
}
</style>
<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="title-box">
      <titles title="车辆统计"/>
      <div class="time">
        <el-date-picker
          v-model="time"
          type="daterange"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="timeChange">
        </el-date-picker>
        <div class="time-ele">
          <i class="el-icon-time"></i>
          {{ time[0] }}
        </div>
        <span>-</span>
        <div class="time-ele">
          <i class="el-icon-time"></i>
          {{ time[1] }}
        </div>
      </div>
    </div>
    <div class="list-box">
      <div class="list" v-for="(item,index) in list">
        <div class="tag">{{ index + 1 }}</div>
        <div class="desc">
          <div class="info">
            <span>{{ item.car_number }}</span>
            <span v-if="Number(item.sum_net_weight) < 10000">{{ Number(item.sum_net_weight).toFixed(1) }}元</span>
            <span v-else>{{ (Number(item.sum_net_weight)/10000).toFixed(2) }}万元</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titles from './title.vue'
export default {
  name:"",
  components: {titles},
  data(){
    return {
      chartLoading:false,
      pickerOptions:{
        shortcuts:[
          {
            text: '近24小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      time:[],
      list:[],
    }
  },
  mounted(){
    this.time = [this.getThirtyTime().thirtyTime,this.getThirtyTime().todayTime];
    this.getEchartData()
  },
  methods:{
    getEchartData(){
      this.chartLoading = true;
      this.$api.getV2Fourth({
        start_time: this.time[0],
        end_time: this.time[1],
        // name: this.$route.params.id
      }).then(res => {
        if(res.code == 1){
          this.list = [...res.data.lists]
          // this.setEchartData(res.data.reverse())
        }
      }).finally(() => {
        this.chartLoading = false;
      })
    },
    timeChange(){
      this.getEchartData();
    },
    getThirtyTime(){
      let today = new Date();
      let today_m = today.getMonth() + 1;
      let today_d = today.getDate();
      let todayTime = `${today.getFullYear()}-${today_m < 10 ? '0' + today_m : today_m}-${today_d < 10 ? '0' + today_d : today_d}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirty_m = thirty.getMonth() + 1;
      let thirty_d = thirty.getDate();
      let thirtyTime = `${thirty.getFullYear()}-${thirty_m < 10 ? '0' + thirty_m : thirty_m}-${thirty_d < 10 ? '0' + thirty_d : thirty_d}`
      return {
        todayTime,
        thirtyTime
      }
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title-box{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .time{
      width: 250px;
      display: flex;
      align-items: center;
      position: relative;
      .time-ele{
        height: 28px;
        background: rgba(1, 47, 107, 0.4);
        border: 1px solid #012F6B;
        font-size: 14px;
        color: #63B2FF;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 2px;
        cursor: pointer;
        i{
          margin-right: 5px;
        }
      }
      span{
        margin: 0 6px;
        font-size: 18px;
        color: #63B2FF;
      }
    }
    .time /deep/.el-date-editor{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  .chart-title{
    box-sizing: border-box;
    width: 100%;
    padding-left: calc(20px + 3em);
    display: flex;
    align-items: center;
    margin: 16px 0 12px 0;
    .ele{
      flex: 1;
      font-size: 15px;
      color: #fff;
      line-height: 22px;
      text-align: center;
    }
  }
  .list-box{
    width: 100%;
    flex: 1;
    padding-top: 8px;
    position: relative;
    bottom: -12px;
    .list{
      width: calc(100% + 24px);
      box-sizing: border-box;
      position: relative;
      left: -12px;
      height: 10%;
      border-top: 1px solid #0F3DB0;
      display: flex;
      align-items: center;
      padding: 0 50px;
      .tag{
        width: 50px;
        height: 36px;
        background: url('../../assets/image/data/detail/tag-1.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #FF941B;
        font-weight: 600;
        margin-right: 50px;
      }
      &:first-child{
        .tag{
          color: #FF3835;
        }
      }
      .desc{
        flex: 1;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &::before{
          content: '';
          display: block;
          background: url('../../assets/image/data/detail/tag-2.png') no-repeat;
          background-size: 100% 100%;
          width: 6px;
          height: 3px;
        }
        &::after{
          content: '';
          display: block;
          background: url('../../assets/image/data/detail/tag-2.png') no-repeat;
          background-size: 100% 100%;
          width: 6px;
          height: 3px;
          transform: rotate(180deg);
        }
        .info{
          flex: 1;
          padding: 0 20px;
          display: flex;
          align-items: center;
          span{
            flex: 1;
            overflow: hidden;
            font-size: 16px;
            color: #82B9E2;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <data-title title="车次数据分析" :width="120"></data-title>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import * as echarts from 'echarts';
export default {
  name:"chart-4",
  components:{dataTitle},
  data(){
    return {
      chartLoading:false,
    }
  },
  mounted() {
    this.getEchartData()
  },
  methods: {
    getEchartData(){
      this.chartLoading = true;
      this.$api.getChartCarNum({
        start_time: '2022-01-01 00:00:00',
        end_time: '2022-09-01 00:00:00'
        // factory_id: ''
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(this.sortData(res.data.info.sort((a,b) => {
            return a.num - b.num
          })))
        }
      }).finally(() => {
        setTimeout(() => {
          this.chartLoading = false;
        },200)
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      if (chart) {
        this.myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: arr.map(ele => {
              return ele.name > 2 ?  `${ele.name}次以内` : `${ele.name}次`
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => {
              return value + '辆'
            }
          },
          grid: {
            top:20,
            right: 20,
            bottom:20,
            left: 70,
          },
          series: [
            {
              name: '共计',
              data: arr.map(ele => {
                return ele.list.length
              }),
              type: 'bar',
              barWidth: 12,
              colorBy: 'data'
            },
            {
              type: 'line',
              smooth: true,
              color:'#43E9E4',
              label: {
                show: true,
                position: 'top',
                color:'#43E9E4'
              },
              data: arr.map(ele => {
                return ele.list.length
              }),
              tooltip:{
                show: false
              }
            }
          ]
        }
        this.myChart.setOption(option);
      }
    },
    sortData(arr, many = 10){
      let max = Math.ceil(arr[arr.length -1].num / 10) * 10;
      let list = [];
      for(let i = 0; i<= max; i += max / many){
        if( i > 0){
          list.push(i)
        }
      }
      if(!list.find(ele => {
        return ele == 1
      })){
        list.unshift(1)
      }
      let new_list = list.map((ele,index) => {
        return {
          name: ele,
          list: arr.filter(e => {
            return e.num > (index > 0 ? list[index - 1] : 0) && e.num <= ele
          })
        }
      })
      return new_list || []
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="section">
    <div class="ele" v-loading="chart1Loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <div class="title">本月商品交易趋势</div>
      <div class="charts" ref="chart1"></div>
    </div>
    <div class="ele" v-loading="chart2Loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <div class="title">运费价格（近30天）</div>
      <div class="charts" ref="chart2"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name:"",
  data(){
    return {
       chart1Loading:false,
       chart2Loading:false,
    }
  },
  mounted(){
    this.getEchart1Data()
    this.getEchart2Data()
  },
  methods:{
    getEchart1Data(){
      this.getMountDay();
      this.chart1Loading = true;
      this.$api.getBtmLeftData({
        start_time: this.getMountTime().start,
        end_time: this.getMountTime().end
      }).then(res => {
        if(res.code == 1){
          this.setEchart1Data(res.data.lists)
        }
      }).finally(() => {
        this.chart1Loading = false;
      })
    },
    setEchart1Data(arr){
      const chart = this.$refs.chart1;
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.getMountDay(),
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：万/元',
            nameLocation: 'end',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          dataZoom: [
            {
              type: "inside",//inside鼠标缩放
              show: true,
              filterMode: 'none',
              xAxisIndex: [0],
            }
          ],
          legend: {
            data: arr.map(ele=>{
              return ele.goods_name
            }),
            right: 0,
            textStyle:{
              color:'#B2E6FF'
            },
            icon:'rect'
          },
          series: arr.map(ele=>{
            return {
              name:ele.goods_name,
              type: 'line',
              showSymbol: false,
              smooth: true,
              color:ele.color_val,
              data: ele.sum_money_list.map(e => {
                return Number(e.new_sum_money)
              })
            }
          }),
          grid: {
            top:30,
            right: 30,
            bottom:30,
            left:50
          },
        };
        myChart.setOption(option);
      }
    },
    getEchart2Data(){
      this.chart2Loading = true;
      this.$api.getFreightHistory({
        start_time: this.getThirtyTime().thirtyTime,
        end_time: this.getThirtyTime().todayTime
      }).then(res => {
        if(res.code == 1){
          this.setEchart2Data(res.data.lists)
        }
      }).finally(() => {
        this.chart2Loading = false;
      })
    },
    setEchart2Data(arr){
      const chart = this.$refs.chart2;
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data:  arr.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：元',
            nameLocation: 'end',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          dataZoom: [
            {
              type: "inside",//inside鼠标缩放
              show: true,
              filterMode: 'none',
              xAxisIndex: [0],
            }
          ],
          series: [
            {
              data: arr.map(ele => {
                return Number(ele.freight_unit_price) || 0
              }),
              type: 'line',
              color:'#0E9CFF',
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(14, 156, 255, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(14, 156, 255, 0.1)'
                  }
                ])
              }
            }
          ],
          grid: {
            top:30,
            right: 30,
            bottom:30,
            left:50
          },
        };
        myChart.setOption(option);
      }
    },
    getMountTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`
      let startTime = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-01`
      return {
        end: todayTime,
        start: startTime
      }
    },
    getMountDay(){
      let nowdays = new Date();
      let year = nowdays.getFullYear();
      let month = nowdays.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let myDate = new Date(year, month, 0);
      let tempList = [];
      for (let i = 1; i <= myDate.getDate(); i++) {
        if (i < 10) {
          tempList.push(month + "-" + "0" + i);
        } else {
          tempList.push(month + "-" + i);
        }
      }
      return tempList;
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  },
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  .ele{
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 40px;
    .charts{
      width: 100%;
      flex: 1;
    }
  }
}
.title{
  width: 220px;
  height: 32px;
  background: url('../../assets/image/data/title-bg.png') no-repeat;
  background-size: 100% 100%;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  padding-left: 36px;
  margin-top: 20px;
}
</style>
<template>
    <vue-scroll :ops="ops" class="bg">
      <dv-full-screen-container style="position: relative">
        <div class="header">
          <div class="header-l">
            <div class="box">
              <i class="el-icon-time"></i>
              <times />
            </div>
          </div>
          <div class="header-t">货行千里数据平台</div>
          <div class="header-r">
            <div class="btn" @click.stop="$router.push('/map')">地图数据</div>
            <i class="el-icon-switch-button" @click="logout"></i>
          </div>
        </div>
        <div class="data-section">
          <div class="center">
            <div class="center-left">
              <data-card>
                <dataCrad1></dataCrad1>
              </data-card>
            </div>
            <div class="center-right">
              <div class="center-right-item">
                <data-card>
                  <dataCrad2/>
                </data-card>
              </div>
              <div class="center-right-item center-right-btm">
                <div class="center-right-btm-left">
                  <data-card>
                    <dataCrad3/>
                  </data-card>
                </div>
                <div class="center-right-btm-right">
                  <data-card>
                    <dataCrad4/>
                  </data-card>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="bottom-item">
              <data-card>
                <dataCrad5/>
              </data-card>
            </div>
            <div class="bottom-item">
              <data-card>
                <dataCrad6 />
              </data-card>
            </div>
          </div>
        </div>
        <!-- <div class="data-section">
          <div class="center">
            <div class="center-item">
              <centerLeft/>
            </div>
            <div class="center-box">
              <center></center>
            </div>
            <div class="center-item">
              <centerRight/>
            </div>
          </div>
          <div class="bottom">
            <bottom/>
          </div>
        </div> -->
      </dv-full-screen-container>
    </vue-scroll>
</template>
<script>
import times from '../components/dataTimes.vue'
import dataCard from '../components/data-crad.vue'
import dataCrad1 from '../components/card/data-card-1.vue'
import dataCrad2 from '../components/card/data-card-2.vue'
import dataCrad3 from '../components/card/data-card-3.vue'
import dataCrad4 from '../components/card/data-card-4.vue'
import dataCrad5 from '../components/card/data-card-5.vue'
import dataCrad6 from '../components/card/data-card-6.vue'

import centerLeft from '../components/card/center-left.vue'
import centerRight from '../components/card/center-right.vue'
import center from '../components/card/center.vue'
import bottom from '../components/card/bottom.vue'

export default {
  name:"index",
  components:{times,dataCard,dataCrad1,dataCrad2,dataCrad3,dataCrad4,dataCrad5,dataCrad6},
  data(){
    return {
      ops:{
        scrollPanel:{
          scrollingX: false,
        },
      }
    }
  },
  methods:{
    logout(){
      this.$store.commit('TOKEN',null)
      this.$store.commit('WALLID',null)
      this.$store.commit('USERINFO',null)
      this.$router.replace('/login')
    }
  }
}
</script>
<style scoped lang='less'>
.bg{
  background: url('../assets/image/data/bg.png') no-repeat;
  background-size: 100% 100%;
}
.header{
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .header-l{
    width: 535px;
    height: 74px;
    background: url('../assets/image/data/top-left.png') no-repeat;
    background-size: 100% 100%;
    padding: 16px 180px 12px 16px;
    box-sizing: border-box;
    .box{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #D2E3FF;
      i{
        margin: 0 12px 0 20px;
        color: #00cadb;
        font-size: 22px;
      }
    }
    
  }
  .header-r{
    width: 535px;
    height: 74px;
    background: url('../assets/image/data/top-right.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    .btn{
      width: 88px;
      height: 38px;
      background: linear-gradient(360deg, #0054BE 0%, #20C7F4 100%);
      border-radius: 2px;
      line-height: 38px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
      margin-left: 396px;
      margin-top: 20px;
    }
    i{
      margin-left: 10px;
      margin-top: 30px;
      color: #00cadb;
      font-size: 22px;
      cursor: pointer;
    }
  }
  .header-t{
    flex: 1;
    height: 100%;
    background: url('../assets/image/data/top-title.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    font-style: italic;
    color: #FFFFFF;
    padding-bottom: 6px;
    line-height: 84px;
  }
}
.data-section{
  width: 100%;
  height: calc(100% - 90px);
  box-sizing: border-box;
  padding: 10px 20px 16px;
  display: flex;
  flex-direction: column;
  .center{
    width: 100%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    .center-left{
      height: 100%;
      min-width: 36%;
      max-width: 700px;
      margin-right: 16px;
    }
    .center-right{
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .center-right-item{
        width: 100%;
        flex: 1;
        margin-bottom: 12px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .center-right-btm{
        display: flex;
        align-items: center;
        overflow: hidden;
        .center-right-btm-left{
          flex: 1;
          height: 100%;
          margin-right: 12px;
        }
        .center-right-btm-right{
          height: 100%;
          width: 35%;
          // max-width: 430px;
        }
      }
    }
    .center-box{
      padding: 0 40px;
      box-sizing: border-box;
      flex: 1;
      height: 100%;
      overflow: hidden;
    }
    .center-item{
      width: 432px;
      height: 100%;
      min-height: 593px;
      background: url('../assets/image/data/left-bg.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .bottom{
    width: 100%;
    min-height: 32%;
    max-height: 326px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bottom-item{
      height: 100%;
      flex: 1;
      margin-right: 16px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
import {get, post, php_post} from './axios.js';
 
let bsae_api = 'https://api.huoxingqianli.com'

let bsae_api_dev = 'https://api.huoxingqianli.com'

 
export default {
  bsae_api: bsae_api,
  login : params => { return post(`${bsae_api}/api/user/login`,params) },


  // 地图相关
  mineconList : params => { return get(`${bsae_api}/api/wall-organization/factory/lists`,params) }, // 矿山和商砼列表
  goodsList : params => { return get(`${bsae_api}/api/wall-organization/factory-goods/lists`,params) }, // 矿山和商砼物料列表
  concreteOffer : params => { return post(`${bsae_api}/api/wall-data/commercial-concrete-offer`,params) }, // 商砼报价
  concreteOfferHistory : params => { return post(`${bsae_api}/api/wall-data/commercial-concrete-offer-history`,params) }, // 商砼历史报价
  carList : params => { return get(`${bsae_api}/api/wall-organization/car/lists`,params) }, // 车辆列表
  getConTop : params => { return get(`${bsae_api}/api/wall-organization/factory/sale-ranking`,params) }, // 商砼top20
  homeSearch : params => { return get(`${bsae_api}/api/wall-organization/search`,params) }, // 搜索
  
  getMineDetail : params => { return get(`${bsae_api}/api/wall-organization/factory/factory-detail`,params) }, // 矿山详情
  mineconSaleRank : params => { return get(`${bsae_api}/api/wall-organization/factory-goods/sale-ranking`,params) }, // 矿山销售排行
  getConcreteDetail : params => { return get(`${bsae_api}/api/wall-organization/factory/commercial-concrete-detail`,params) }, // 商砼详情
  getCarList : params => { return get(`${bsae_api}/api/wall-organization/car/lists`,params) }, // 获取车辆列表
  getCarDetail : params => { return get(`${bsae_api}/api/wall-organization/car/detail`,params) }, // 获取车辆详情
  getCarMineList : params => { return get(`${bsae_api}/api/wall-organization/car/factory-list`,params) }, // 获取车辆到达过的矿山
  getCarConlist : params => { return get(`${bsae_api}/api/wall-organization/car/commercial-concrete-list`,params) }, // 获取车辆到达过的商砼
  getDriverDetails : params => { return get(`${bsae_api}/api/wall-organization/car/get-car-driver-list`,params) }, // 获取关联司机
  getCarOrderList : params => { return get(`${bsae_api}/api/wall-organization/factory-order/get-order-list`,params) }, // 获取关联司机
  

  getFreightHistory : params => { return get(`${bsae_api}/api/wall-organization/freight-history`,params) }, // 运费单价走势
  getOrderTotal : params => { return get(`${bsae_api}/api/wall-organization/factory-order/get-order-statistics`,params) }, // 业务总计

  getCity : params => { return get(`${bsae_api}/api/wall-organization/city-lists`,params) }, // 获取城市
  

  // 新首页数据接口
  getLeftData : params => { return get(`${bsae_api}/api/wall-organization/wall-organization-record`,params) }, // 获取左边的数据
  getBtmLeftData : params => { return get(`${bsae_api}/api/wall-organization/wall-organization-goods-record`,params) }, // 获取本月交易趋势
  getNewBtmLeftData : params => { return get(`${bsae_api}/api/wall-organization/sub-company-index/get-sub-company-trend-city-list`,params) }, // 获取分公司本月交易趋势
  getNewCompanyRank : params => { return get(`${bsae_api}/api/wall-organization/sub-company-index/get-sub-company-rank-list`,params) }, // 获取分公司排行
  getNewCompanyCityList: params => { return get(`${bsae_api}/api/wall-organization/sub-company-index/get-city-list`,params) }, // 获取分公司地区
  getNewCompanyFactoryRank: params => { return get(`${bsae_api}/api/wall-organization/sub-company-index/get-factory-rank-list`,params) }, // 获取分公司地区数据
  getNewCompanyTransactionData: params => { return get(`${bsae_api}/api/wall-organization/sub-company-index/transaction-data`,params) }, // 获取分公司交易数据

  // 订单
  getOrderList : params => { return post(`${bsae_api}/api/wall-data/get-order-list`,params) }, // 获取订单列表


  getChartDistances : params => { return get(`${bsae_api_dev}/analysis/get-distances`,params) }, 
  getChartRegions : params => { return get(`${bsae_api_dev}/analysis/get-regions`,params) }, 
  getChartConcrete : params => { return get(`${bsae_api_dev}/analysis/get-commercial-concrete`,params) }, 
  getChartCarNum : params => { return get(`${bsae_api_dev}/analysis/get-car-number`,params) }, 
  

  // 详情
  getV2First : params => { return get(`${bsae_api}/api/wall-organization/v2/goods/lists`,params) }, // 当天交易数据
  getV2Second : params => { return get(`${bsae_api}/api/wall-organization/v2/factory/lists`,params) }, // 矿山销售排行
  getV2Third : params => { return get(`${bsae_api}/api/wall-organization/v2/order/statistics`,params) }, // 顶部数据统计
  getV2Fourth : params => { return get(`${bsae_api}/api/wall-organization/v2/car/lists`,params) }, // 司机统计
  getV2FifthFactory : params => { return get(`${bsae_api}/api/wall-organization/v2/transfer/front-factory`,params) }, // 交易趋势-矿山
  getV2Fifth : params => { return get(`${bsae_api}/api/wall-organization/v2/transfer/front`,params) }, // 交易趋势-数据
  getV2Sixth : params => { return get(`${bsae_api}/api/wall-organization/v2/transfer/front-money`,params) }, // 运费金额
  getV2Info: params => { return get(`${bsae_api}/api/wall-organization/v2/sub-company/info`,params) }, // 分公司信息
}
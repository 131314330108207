<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <data-title title="分公司近一个月销售交易趋势-商砼" :width="256" class="title-5"></data-title>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import * as echarts from 'echarts';
export default {
  name:"data-card-1",
   components:{dataTitle},
  data(){
    return {
       chartLoading:false,
    }
  },
  mounted(){
    this.getEchartData()
  },
  methods:{
    getEchartData(){
      this.chartLoading = true;
      this.$api.getNewBtmLeftData({
        start_time: this.getThirtyTime().thirtyTime,
        end_time: this.getThirtyTime().todayTime
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(res.data.lists)
        }
      }).finally(() => {
        this.chartLoading = false;
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  arr[0].detail.map(ele => {
              return ele.date.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            name: '单位：万元',
            nameLocation: 'end',
            nameTextStyle:{
              align:'left',
              color:'#B2E6FF'
            },
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer : {       
                type : 'shadow'
            }
          },
          legend: {
            data: arr.map(ele => {
              return ele.wall_organization_name
            }),
            type: 'scroll',
            left: 'right',
            top: 6,
            textStyle: {
              color: '#EFFAFF'
            },
            pageTextStyle:{
              color: '#fff'
            },
            pageIconColor: '#B2E6FF',
            pageIconInactiveColor: '#1B407F',
            padding:[0,10,0,350]
          },
          series: arr.map(ele => {
            return {
              name: ele.wall_organization_name,
              type: 'bar',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: ele.color[0] },
                  { offset: 1, color: ele.color[1] }
                ]),
                borderRadius: 20
              },
              tooltip: {
                valueFormatter: function (value) {
                  if(value > 0){
                    return value + '万元';
                  }else{
                    return value;
                  }
                }
              },
              // barWidth: 4,
              data: ele.detail.map(e => {
                return Number(e.goods_total_money) || 0
              })
            }
          }),
          grid: {
            top:70,
            right: 10,
            bottom:20,
            left:30
          },
        };
        myChart.setOption(option);
      }
    },
    getMountTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`
      let startTime = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}-01`
      return {
        end: todayTime,
        start: startTime
      }
    },
    getMountDay(){
      let nowdays = new Date();
      let year = nowdays.getFullYear();
      let month = nowdays.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let myDate = new Date(year, month, 0);
      let tempList = [];
      for (let i = 1; i <= myDate.getDate(); i++) {
        if (i < 10) {
          tempList.push(month + "-" + "0" + i);
        } else {
          tempList.push(month + "-" + i);
        }
      }
      return tempList;
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .title-5{
    position: absolute;
    left: 0;
    top: 0;
  }
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <transition name="el-fade-in-linear">
    <div class="chart-shadow" v-if="show" @click="$emit('close')">
      <div class="chart-content" @click.stop>
        <div class="chart-header">
          <span>{{ title }}</span>
          <div class="right">
            <!-- <div class="time">
              <el-date-picker
                v-model="time"
                type="daterange"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                @change="timeChange">
              </el-date-picker>
              <div class="time-ele">
                <i class="el-icon-time"></i>
                {{ time[0] }}
              </div>
              <span>-</span>
              <div class="time-ele">
                <i class="el-icon-time"></i>
                {{ time[1] }}
              </div>
            </div> -->
            <i class="el-icon-close" @click.stop="$emit('close')"></i>
          </div>
        </div>
        <div class="chart-body" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <div class="chart-item">
            <titles :title="title1 ? `当天数据 （${title1}）` : '当天数据'"/>
            <div class="chart-small">
              <div class="chart-ele" style="width: 56%">
                <div class="charts" ref="chart1"></div>
              </div>
              <div class="chart-ele" style="width: 44%">
                <div class="charts" ref="chart2"></div>
              </div>
            </div>
          </div>
          <div class="chart-item">
            <titles :title="title3 ? `历史数据 （${title3}）` : '历史数据'"/>
            <div class="charts" ref="chart3"></div>
          </div>
          <div class="chart-item-big">
            <div class="switch">
              <div class="switch-ele">
                <span>总量/类别切换</span>
                <el-switch
                  v-model="typeSwitchVal"
                  active-color="#00f7ff"
                  inactive-color="#63B2FF"
                  @change="typeChange">
                </el-switch>
              </div>
              <!-- <transition name="el-fade-in">
                <div class="switch-ele" v-if="typeSwitchVal" style="margin-left: 24px">
                  <span>折线/柱状切换</span>
                  <el-tooltip :content="switchVal ? '柱状' : '折线'" placement="top">
                    <el-switch
                      v-model="switchVal"
                      active-color="#00f7ff"
                      inactive-color="#63B2FF"
                      @change="switchChange">
                    </el-switch>
                  </el-tooltip>
                </div>
              </transition> -->
            </div>
            <div class="charts" ref="chart4"></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import titles from './title.vue'
import * as echarts from 'echarts';
export default {
  name:"chart-detail",
  components:{titles},
  props:{
    show: {
      default: false
    },
    title:{
      default: ''
    },
    id:{
      default: ''
    },
    query: {
      default: null
    },
    queryList: {
      default : null
    }    
  },
  data(){
    return {
      title1: '',
      title2: '',
      title3: '',
      title4: '',
      chartLoading: false,
      time:[],
      selectDate: '',
      //日期选择范围在一个月内
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return time.getTime() < minTime || time.getTime() > maxTime || time.getTime() > Date.now()
          }
          return time.getTime() > Date.now()
        }
      },
      typeSwitchVal: true,
      switchVal: true
    }
  },
  watch: {
    show(val){
      if(val){
        this.list = [...this.queryList];
        this.list.forEach(element => {
          element.total = (element.list.reduce((pre,cur) => {
              return pre + cur.money * 100 ;
            }, 0) / 100).toFixed(0)
        })
        if(this.list[this.query.dataIndex].total > 0){
          if(this.query.seriesType == 'line'){
            this.param = {
              dataIndex: this.query.dataIndex,
              seriesName: this.list[this.query.dataIndex].list[0].name
            }
          }else{
            this.param = {...this.query};
          }          
        }else{
          let index = this.list.findIndex(ele => {
            return ele.total > 0
          })
          let idx = index > -1 ? index : 0
          this.param = {
            dataIndex: idx,
            seriesName: this.list[idx].list[0].name
          }
        }
        this.setEchartData(this.param,this.list)
        this.time = [this.list[0].time,this.list[this.list.length - 1].time]
      }
    }
  },
  mounted(){
    this.$nextTick(() => {
      const body = document.querySelector("body");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  },
  methods: {
    timeChange(){
      this.getEchartData()
    },
    getEchartData(){
      this.chartLoading = true;
      this.$api.getV2Fifth({
        factory_id: this.id,
        start_time: this.time[0],
        end_time: this.time[1]
      }).then(res => {
        if(res.code == 1){
          if(res.data.length > 0){
            this.list = this.mapData(res.data);
            this.param = {
              dataIndex: 0,
              seriesName: this.list[0].list[0].name
            }
            this.setEchartData(this.param,this.list)
          }else{
            this.$message({
              message: '暂无该时间段数据',
              type: 'warning'
            });
          }
        }
      }).finally(() => {
        setTimeout(() => {
          this.chartLoading = false;
        },200)
      })
    },
    setEchartData(param, list){
      console.log(param)
      this.$nextTick(() => {
        this.setEchart1Data(list[param.dataIndex])
        this.setEchart2Data(list[param.dataIndex])
        this.setEchart3Data(this.sortData(param.seriesName, list))
        this.setEchart4Data(list,this.typeSwitchVal)
      })
    },
    setEchart1Data(arr){
      this.title1 = arr.time;
      const chart1 = this.$refs.chart1;
      let list = arr.list.filter(ele => {
        return ele.money > 0
      })
      if (this.myChart1) {//关键步骤
        this.myChart1.dispose()
        this.myChart1 = null
      }
      if(chart1) {
        this.myChart1 = echarts.init(chart1)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: list.map(ele => {
              return ele.name
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis',
            valueFormatter: function (value) {
              return value + '元';
            }
          },
          series: [
            {
              type: 'bar',
              stack: 'total',
              barWidth: 20,
                emphasis: {
                  focus: 'series'
                },
              data: list.map(ele => {
                return ele.money
              }),
            }
          ],
          grid: {
            top:20,
            right: 10,
            bottom:20,
            left:60
          },
        };
        let _this = this;
        this.myChart1.on('click', function (params) {
          _this.setEchart3Data(_this.sortData(params.name, _this.list))
        });
        this.myChart1.setOption(option);
      }
    },
    setEchart2Data(arr){
      this.title2 = arr.time;
      const chart2 = this.$refs.chart2;
      let list = arr.list.filter(ele => {
        return ele.money > 0
      }).sort((a,b) => {
        return a.money - b.money
      })
      let query = list.map(ele => {
        return ele.money
      })
      let max = Math.max(...query);
      let min = Math.min(...query);

      console.log(1/(1 + Math.exp(-2.9)))
      console.log(1/(1 + Math.exp(-0.01)))
      if (this.myChart2) {//关键步骤
        this.myChart2.dispose()
        this.myChart2 = null
      }
      if(chart2) {
        this.myChart2 = echarts.init(chart2)
        const option = {
          title: {
            text: '占比',
            left: 'center',
            textStyle:{
              color:'#FFD264'
            }
          },
          tooltip: {
            trigger: 'item'
          },
          visualMap: {
            show: false,
            min: Math.min(...query) * 0.6,
            max: Math.max(...query) * 1.4,
            inRange: {
              colorLightness: [0, 1]
            }
          },
          series: [
            {
              name: '销售占比',
              type: 'pie',
              radius: ['10%', '60%'],
              roseType: 'radius',
              avoidLabelOverlap: false,
              label: {
                color: '#aaa'
              },
              itemStyle: {
                borderRadius: 2,
                color: '#c23531',
                shadowBlur: 50,
                shadowColor: 'rgba(0, 0, 0, 0.1)'
              },
              emphasis: {
                label: {
                  color: '#fff',
                  fontWeight: '500'
                }
              },
              tooltip: {
                trigger: 'item',
                formatter: '{b} : ({d}%)'
              },
              data: list.map(ele => {
                return {
                  value: ele.money,
                  name: ele.name
                }
              })
            }
          ]
        };
        let _this = this;
        this.myChart2.on('click', function (params) {
          _this.setEchart3Data(_this.sortData(params.name, _this.list))
        });
        this.myChart2.setOption(option);
      }
    },
    setEchart3Data(arr){
      console.log(111,arr)
      this.title3 = arr.name;
      let list = arr.list;
      const chart3 = this.$refs.chart3;
      if (this.myChart3) {//关键步骤
        this.myChart3.dispose()
        this.myChart3 = null
      }
      if(chart3) {
        this.myChart3 = echarts.init(chart3)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: list.map(ele => {
              return ele.time.slice(5,10)
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis',
            valueFormatter: function (value) {
              return value + '元';
            }
          },
          series: [
            {
              name: arr.name,
              type: 'bar',
              stack: 'total',
              barWidth: 10,
                emphasis: {
                  focus: 'series'
                },
              data: list.map(ele => {
                return ele.money
              }),
            }
          ],
          grid: {
            top:20,
            right: 10,
            bottom:20,
            left:60
          },
        };
        let _this = this;
        this.myChart3.on('click', function (params) {
          _this.param.dataIndex = params.dataIndex;
          _this.setEchart1Data(_this.list[params.dataIndex])
          _this.setEchart2Data(_this.list[params.dataIndex])
          // _this.setEchart4Data(_this.list, _this.typeSwitchVal)
        });
        this.myChart3.setOption(option);
      }
    },
    setEchart4Data(arr, flag = true){
      const chart4 = this.$refs.chart4;
      let new_arr = arr[0].list.map(element => {
        return {
          name: element.name,
          list: arr.map(ele => {
            let item = ele.list.find(e => {
              return e.name == element.name
            })
            return item ? Number(item.money).toFixed(0) : 0
          })
        }
      })
      let legend_arr = new_arr.map(ele => {
        return ele.name
      });
      if (this.myChart4) {//关键步骤
        this.myChart4.dispose()
        this.myChart4 = null;
        this.switchVal = true;
      }
      if(chart4){
        this.myChart4 = echarts.init(chart4)
        let option = {}
        if(flag){
          option = {
            xAxis: {
              type: 'category',
              boundaryGap: true,
              data:  arr.map(ele => {
                return ele.time.slice(5,10)
              }),
              axisLabel:{
                color:'#B2E6FF'
              },
              axisTick:{
                show: false
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle:{
                  width: 0.5,
                  type:'dashed',
                  color:'#1B407F',
                }
              },
              axisLabel:{
                color:'#B2E6FF'
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter:(params) => {
                var res = `${params[0].name} <br/>`
                for (const item of params) {
                  if (item.value > 0) {
                    res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}元<br/>`
                  }
                }
                return res
              }
            },
            legend: {
              left: 'right',
              type:'scroll',
              data: legend_arr,
              selected: this.filterData(this.list[this.param.dataIndex], legend_arr),
              textStyle:{
                color: '#ffffff'
              },
              pageTextStyle:{
                color: '#fff'
              },
              pageIconColor: '#B2E6FF',
              pageIconInactiveColor: '#1B407F'
            },
            series: [
              ...new_arr.map(ele => {
                return {
                  name: ele.name,
                  type: 'bar',
                  showSymbol: false,
                  smooth: true,
                  emphasis: {
                    focus: 'series'
                  },
                  data: ele.list
                }
              }),
            ],
            grid: {
              top:50,
              right: 10,
              bottom:20,
              left:60
            },
          }
        }else{
          let total = arr.map(ele => {
            return (ele.list.reduce((pre,cur) => {
              return pre + cur.money * 100 ;
            }, 0) / 100).toFixed(0)
          })
          option = {
            xAxis: {
              type: 'category',
              boundaryGap: true,
              data:  arr.map(ele => {
                return ele.time.slice(5,10)
              }),
              axisLabel:{
                color:'#B2E6FF'
              },
              axisTick:{
                show: false
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle:{
                  width: 0.5,
                  type:'dashed',
                  color:'#1B407F',
                }
              },
              axisLabel:{
                color:'#B2E6FF'
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter:(params) => {
                var res = `${params[0].name} <br/>`
                for (const item of params) {
                  if (item.value > 0) {
                    res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}元<br/>`
                  }
                }
                return res
              }
            },
            legend: {
              left: 'right',
              type:'scroll',
              data: ['总量','七日均额', '十五日均额','三十日均额'],
              textStyle:{
                color: '#ffffff'
              },
              pageTextStyle:{
                color: '#fff'
              },
              pageIconColor: '#B2E6FF',
              pageIconInactiveColor: '#1B407F'
            },
            series: [
              {
                name: '总量',
                type: 'line',
                color:'#43E9E4',
                showSymbol: false,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  position: 'top',
                  color: '#7DCEF4'
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgba(0,247,255,0.6)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,247,255,0.1)'
                    }
                  ])
                },
                data: total
              },
              {
                name: '七日均额',
                type: 'line',
                showSymbol: false,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: arr.map(ele => {
                  return Number(ele.sevenAverageMoney) || 0
                })
              },
              {
                name: '十五日均额',
                type: 'line',
                showSymbol: false,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: arr.map(ele => {
                  return Number(ele.fifteenAverageMoney) || 0
                })
              },
              {
                name: '三十日均额',
                type: 'line',
                showSymbol: false,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: arr.map(ele => {
                  return Number(ele.thirtyAverageMoney) || 0
                })
              }
            ],
            grid: {
              top:50,
              right: 10,
              bottom:20,
              left:60
            },
          }
        }
        this.myChart4.setOption(option);
      }
    },
    typeChange(val){
      this.setEchart4Data(this.list,val)
    },
    switchChange(val){
      let new_arr = this.list[0].list.map(element => {
        return {
          name: element.name,
          list: this.list.map(ele => {
            let item = ele.list.find(e => {
              return e.name == element.name
            })
            return item ? Number(item.money).toFixed(0) : 0
          })
        }
      })
      if(val){
        this.myChart4.setOption({
          series: [
            ...new_arr.map(ele => {
              return {
                name: ele.name,
                type: 'bar',
                showSymbol: false,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: ele.list
              }
            }),
          ],
        })
      }else{
        this.myChart4.setOption({
          series: [
            ...new_arr.map(ele => {
              return {
                name: ele.name,
                type: 'line',
                showSymbol: false,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: ele.list
              }
            }),
          ],
        })
      }
    },
    sortData(name, list){
      let arr = list.map(ele => {
        let item = ele.list.find(e => {
          return e.name == name
        })
        return {
          time: ele.time,
          money: item.money || 0
        }
      })
      return {
        name : name,
        list: arr
      }
    },
    mapData(list){
      let nameList = [];
      list.forEach(element => {
        element.typeVOS.forEach(ele => {
          let item = nameList.find(e => {
            return e == ele.goodsName
          })
          if(!item){
            nameList.push(ele.goodsName)
          }
        })
      })
      let new_list = list.map(element => {
        return {
          time: element.cutOffTime,
          sevenAverageMoney: element.sevenAverageMoney,
          thirtyAverageMoney: element.thirtyAverageMoney,
          fifteenAverageMoney: element.fifteenAverageMoney,
          list: nameList.map(ele => {
            let eleMoney = element.typeVOS.find(e => {
              return e.goodsName == ele
            });
            let money = eleMoney ? Number(eleMoney.money) : 0;
            return {
              name: ele,
              money: money
            }
          })
        }
      })
      return new_list
    },
    filterData(arr,allArr){
      let list = arr.list.filter(ele => {
        return ele.money > 0
      })
      let obj = {};
      allArr.forEach(ele => {
        if(!list.find(e => {
          return e.name == ele
        })){
          obj[ele] = false
        }
      })
      return obj;
    },
  }
}
</script>
<style scoped lang='less'>
.chart-shadow{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
  overflow: hidden;
  backdrop-filter: blur(3px);
  .chart-content{
    width: 96%;
    background-color: rgba(3, 24, 66, 0.9);
    border-radius: 4px;
    margin: 62px auto;
    .chart-header{
      width: 100%;
      height: 56px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      span{
        font-size: 20px;
        color: #00f7ff;
        font-weight: 500;
      }
      .right{
        display: flex;
        align-items: center;
        .time{
          width: 300px;
          display: flex;
          align-items: center;
          position: relative;
          margin-right: 20px;
          .time-ele{
            flex: 1;
            height: 28px;
            background: rgba(1, 47, 107, 0.4);
            border: 1px solid #012F6B;
            font-size: 14px;
            color: #63B2FF;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border-radius: 2px;
            cursor: pointer;
            i{
              margin-right: 5px;
            }
          }
          span{
            margin: 0 6px;
            font-size: 18px;
            color: #63B2FF;
          }
        }
        .time /deep/.el-date-editor{
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          opacity: 0;
        }
      }
      i{
        font-size: 20px;
        color: #00f7ff;
        cursor: pointer;
      }
    }
    .chart-body{
      height: calc(100vh - 180px);
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .chart-item-big{
        width: 100%;
        height: calc(50% - 12px);
        box-shadow: inset 0px 1px 117px 1px #002263;
        border: 1px solid rgba(19, 68, 197, 0.42);
        box-sizing: border-box;
        padding: 12px;
        display: flex;
        flex-direction: column;
        position: relative;
        .switch{
          position: absolute;
          left: 20px;
          top: 14px;
          z-index: 100;
          display: flex;
          align-items: center;
          span{
            font-size: 12px;
            color: #00f7ff;
            margin-right: 12px;
            // background-image: -webkit-linear-gradient(bottom, #FFD264, #FFA534);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            &:last-child{
              margin-left: 20px;
            }
          }
        }
        .charts{
          flex: 1;
          width: 100%;
          // overflow: hidden;
        }
      }
      .chart-item{
        width: calc(50% - 6px);
        height: calc(50% - 12px);
        box-shadow: inset 0px 1px 117px 1px #002263;
        border: 1px solid rgba(19, 68, 197, 0.42);
        box-sizing: border-box;
        padding: 12px;
        display: flex;
        flex-direction: column;
        .charts{
          flex: 1;
          width: 100%;
          // overflow: hidden;
        }
      }
      .chart-small{
        width: 100%;
        height: 100%;
        display: flex;
        .chart-ele{
          height: 100%;
          display: flex;
        }
      }
    }
  }
}
</style>
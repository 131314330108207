<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="top-title">
      <data-title :title="title" :width="150" :dropdown="true" style="cursor: pointer" @checkCity="checkCity"></data-title>
      <div class="btn-box">
        <div class="btn" 
          v-for="(item,index) in btnList" 
          :key="index" 
          :class="{'btn-active':item.type == active}"
          @click="selectType(item)"
        >{{ item.name }}</div>
      </div>
    </div>
    <div class="box">
      <div class="ele-box" v-for="(item,index) in list" :key="index">
        <div class="ele">
          <span class="name">{{ item.name }}</span>
          <div class="total" v-if="item.material_total_price < 10000">
            <span>{{ item.material_total_price }}</span>元
          </div>
          <div class="total" v-else>
            <span>{{ (item.material_total_price/10000).toFixed(2) }}</span>万元
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
export default {
  name:"data-card-3",
  components:{dataTitle},
  data(){
    return {
       title:'',
       chartLoading:false,
       list:null,
       btnList:[
        {
          name:'今日',
          type:0
        },
        {
          name:'本周',
          type:1
        },
        {
          name:'本月',
          type:2
        },
        {
          name:'近一个月',
          type:3
        },
        {
          name:'不限',
          type:4
        },
      ],
      active:4,
      activeItem:null,
    }
  },
  mounted(){
    this.chartLoading = true;
  },
  methods:{
    selectType(item){
      if(this.active == item.type) return
      this.active = item.type
      this.getInfo(this.activeItem); 
    },
    checkCity(item){
      this.title = `${item.name}-${item.type_name}`
      this.activeItem = item;
      this.getInfo(this.activeItem);
    },
    getInfo(item){
      let param = {
        type: item.type,
        city_code: item.region_code
      }
      if (this.active == 0){
        let today = new Date();
        param.start_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
        param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
      } else if (this.active == 1){
        let today = new Date();
        param.start_time = this.getCurrentWeekFirstDay(new Date());
        param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
      } else if (this.active == 2){
        param.start_time = this.getMountTime().start;
        param.end_time = this.getMountTime().end;
      } else if(this.active == 3){
        param.start_time = this.getThirtyTime().thirtyTime;
        param.end_time = this.getThirtyTime().todayTime;
      }
      this.chartLoading = true;
      this.$api.getNewCompanyFactoryRank(param).then(res => {
        if(res.code == 1){
          this.list = res.data.lists.slice(0,8);
        }
      }).finally(() => {
        setTimeout(()=>{
          this.chartLoading = false;
        },500)
      })
    },
    getCurrentWeekFirstDay(date) {
      var days=date.getDay();
      days=days==0?7:days;
      
        let weekFirstDay = new Date(date - (days - 1) * 86400000 );
        //console.log('===', weekFirstDay);
        let firstMonth = Number(weekFirstDay.getMonth()) + 1;
        // if (firstMonth < 10) {
        //     firstMonth = '0' + firstMonth;
        // }
        let weekFirstDays = weekFirstDay.getDate();
        // if (weekFirstDays < 10) {
        //     weekFirstDays = '0' + weekFirstDays;
        // }
        return weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays;
    },
    getMountTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let startTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-01`
      return {
        end: todayTime,
        start: startTime
      }
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-title{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-box{
      height: 30px;
      display: flex;
      align-items: center;
      .btn{
        height: 28px;
        background: rgba(1, 47, 107, 0.4);
        border: 1px solid #012F6B;
        font-size: 12px;
        color: #63B2FF;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 2px;
        margin-left: 8px;
        cursor: pointer;
      }
      .btn-active{
        background: rgba(110, 178, 255, 0.2);
        border: 1px solid #6EB2FF;
        // box-shadow: 0 0 8px 0 #0093a7 inset;
        color: #6EB2FF;
      }
    }
  }
  .box{
    flex: 1;
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .ele-box{
      width: 50%;
      height: 25%;
      min-height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      box-sizing: border-box;
      &:nth-child(2n){
        .ele{
          background: linear-gradient(90deg, rgba(17,90,208,0.7600) 0%, #001848 100%);
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      &:first-child{
        .ele{
          position: relative;
          &::before{
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: #18E977;
            position: absolute;
            right: -34px;
            z-index: 10;
          }
        }
      }
      &:nth-child(3){
        .ele{
          position: relative;
          &::before{
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: #18E9A1;
            position: absolute;
            right: -34px;
            z-index: 10;
          }
          &::after{
            content: '';
            display: block;
            width: 2px;
            height: 50px;
            background-color: #1B407F;
            position: absolute;
            right: -31px;
            top: -40px;
          }
        }
        
      }
      &:nth-child(5){
        .ele{
          position: relative;
          &::before{
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: #18E9DA;
            position: absolute;
            right: -34px;
            z-index: 10;
          }
          &::after{
            content: '';
            display: block;
            width: 2px;
            height: 50px;
            background-color: #1B407F;
            position: absolute;
            right: -31px;
            top: -40px;
          }
        }
        
      }
      &:nth-child(7){
        .ele{
          position: relative;
          &::before{
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: #18C2E9;
            position: absolute;
            right: -34px;
            z-index: 10;
          }
          &::after{
            content: '';
            display: block;
            width: 2px;
            height: 50px;
            background-color: #1B407F;
            position: absolute;
            right: -31px;
            top: -40px;
          }
        }
      }
    }
    .ele{
      box-sizing: border-box;
      width: 100%;
      // margin: 8px 30px;
      background: linear-gradient(270deg, rgba(17,90,208,0.7600) 0%, #001848 100%);
      height: 26px;
      min-height: 50%;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name{
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 500;
        flex: 1;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      .total{
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 500;
        span{
          color: #FFF352;
          margin-right: 2px;
        }
      }
    }
    
  }
}
</style>
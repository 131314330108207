<template>
  <div class="data-card" :class="{border: isBorder}">
    <div class="icon icon-1"></div>
    <div class="icon icon-2"></div>
    <div class="icon icon-3"></div>
    <div class="icon icon-4"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:"data-card",
  props:{
    isBorder: {
      default: false
    }
  },
  data(){
    return {
       
    }
  },
}
</script>

<style scoped lang='less'>
.data-card{
  width: 100%;
  height: 100%;
  min-height: 50px;
  position: relative;
  box-shadow: inset 0px 1px 117px 1px #002263;
  border: 1px solid #002263;
  padding: 12px;
  box-sizing: border-box;
  .icon{
    width: 12px;
    height: 12px;
    position: absolute;
  }
  .icon-1{
    left: 0;
    top: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #346ea3;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .icon-2{
    right: 0;
    top: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      top: 0;
      right: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .icon-3{
    right: 0;
    bottom: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .icon-4{
    left: 0;
    bottom: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.border{
  box-shadow: inset 0px 1px 117px 1px #002263 !important;
  border: 1px solid #1344C5 !important;
}
</style>
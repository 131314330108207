<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <data-title title="运距数据分析" :width="120"></data-title>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import dataTitle from '../../components/data-title.vue'
import * as echarts from 'echarts';
export default {
  name:"chart-1",
  components:{dataTitle},
  data(){
    return {
      chartLoading:false,
    }
  },
  mounted() {
    this.getEchartData('鑫强建材')
  },
  methods:{
    getEchartData(name){
      this.chartLoading = true;
      this.$api.getChartDistances({
        start_time: '2022-01-01 00:00:00',
        end_time: '2022-09-01 00:00:00'
        // factory_id: ''
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(this.sortData(res.data.info))
        }
      }).finally(() => {
        setTimeout(() => {
          this.chartLoading = false;
        },200)
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      let new_arr = arr[0].list.map(element => {
        return {
          name: element.name,
          list: arr.map(ele => {
            let item = ele.list.find(e => {
              return e.name == element.name
            })
            return item ? Number(item.money).toFixed(0) : 0
          })
        }
      })
      let total = arr.map(ele => {
        return (ele.list.reduce((pre,cur) => {
          return pre + cur.money * 100 ;
        }, 0) / 100).toFixed(0)
      })
      if (chart) {
        this.myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  arr.map(ele => {
              return ele.time + 'KM'
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis',
            formatter:(params) => {
              var res = `${params[0].name} <br/>`
              for (const item of params) {
                if (item.value > 0) {
                  res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}吨<br/>`
                }
              }
              return res
            }
          },
          series: [
            ...new_arr.map(ele => {
              return {
                name: ele.name,
                type: 'bar',
                stack: 'total',
                barWidth: 12,
                emphasis: {
                  focus: 'series'
                },
                data: ele.list
              }
            }),
            {
              name: '总量',
              type: 'line',
              smooth: true,
              color:'#43E9E4',
              label: {
                show: true,
                position: 'top',
                color: '#7DCEF4'
              },
              data: total
            }
          ],
          grid: {
            top:20,
            right: 10,
            bottom:20,
            left:60
          },
        };
        this.myChart.setOption(option);
      }
    },
    sortData(list){
      let nameList = [];
      list.forEach(element => {
        element.lists.forEach(ele => {
          let item = nameList.find(e => {
            return e == ele.goods_name
          })
          if(!item){
            nameList.push(ele.goods_name)
          }
        })
      })
      let new_list = list.map(element => {
        return {
          time: element.distance,
          list: nameList.map(ele => {
            let eleMoney = element.lists.find(e => {
              return e.goods_name == ele
            });
            let money = eleMoney ? Number(eleMoney.weight) : 0;
            return {
              name: ele,
              money: money
            }
          })
        }
      })
      return new_list
    },
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="item">
      <div class="top-title">
        <div class="btn-box">
          <div class="box-section">
            <div class="btn" 
              v-for="(item,index) in btnList" 
              :key="index" 
              :class="{'btn-active':item.type == active}"
              @click="selectType(item)"
            >{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="ele-box" v-if="info">
        <div class="ele" @click.stop="openOrder(0,'已下单')">
          <div class="tag">
            <span>已下单</span>
          </div>
          <div class="text">
            <countTo :startVal='0' :endVal='Number(info.order_num)' :duration='2000'></countTo>
            <span class="unit">单</span>
          </div>
        </div>
        <div class="ele">
          <div class="tag" @click.stop="openOrder(1,'已取消')">
            <span>已取消</span>
          </div>
          <div class="text" @click.stop="openOrder(1,'已取消')">
            <countTo :startVal='0' :endVal='Number(info.cancel_num)' :duration='2000'></countTo>
            <span class="unit">单</span>
          </div>
        </div>
        <div class="ele">
          <div class="tag" @click.stop="openOrder(3,'未完成')">
            <span>未完成</span>
          </div>
          <div class="text" @click.stop="openOrder(3,'未完成')">
            <countTo :startVal='0' :endVal='Number(info.not_num)' :duration='3000'></countTo>
            <span class="unit">单</span>
          </div>
        </div>
        <div class="ele">
          <div class="tag" @click.stop="openOrder(2,'已完成')">
            <span>已完成</span>
          </div>
          <div class="text" @click.stop="openOrder(2,'已完成')">
            <countTo :startVal='0' :endVal='Number(info.complete_num)' :duration='2000'></countTo>
            <span class="unit">单</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="btm-box" v-if="info">
        <div class="btm-ele">
          <img src="../../assets/image/data/detail/data-1.png" alt="">
          <div class="desc">
            <div class="text">下单数量</div>
            <div class="num">
              <countTo :startVal='0' :endVal='Number(info.order_num)' :duration='2000'></countTo>
              <span class="unit">单</span>
            </div>
          </div>
        </div>
        <div class="btm-ele">
          <img src="../../assets/image/data/detail/data-2.png" alt="">
          <div class="desc">
            <div class="text">下单总吨数</div>
            <div class="num">
              <countTo :startVal='0' :endVal='Number(info.pre_weight)' :duration='2000'></countTo>
              <span class="unit">吨</span>
            </div>
          </div>
        </div>
        <div class="btm-ele">
          <img src="../../assets/image/data/detail/data-4.png" alt="">
          <div class="desc">
            <div class="text">贸易总金额</div>
            <div class="num" v-if="Number(info.total_money) > 1000000">
              <countTo :startVal='0' :endVal='Number((Number(info.total_money)/10000).toFixed(1))' :duration='3000' :decimals="1"></countTo>
              <span class="unit">万元</span>
            </div>
            <div class="num" v-else>
              <countTo :startVal='0' :endVal='Number(info.total_money)' :duration='3000'></countTo>
              <span class="unit">元</span>
            </div>
          </div>
        </div>
        <div class="btm-ele">
          <img src="../../assets/image/data/detail/data-3.png" alt="">
          <div class="desc">
            <div class="text">充值总金额</div>
            <div class="num" v-if="Number(info.recharge_money) > 1000000">
              <countTo :startVal='0' :endVal='Number(info.recharge_money)/10000' :decimals="1" :duration='3000'></countTo>
              <span class="unit">万元</span>
            </div>
            <div class="num" v-else>
              <countTo :startVal='0' :endVal='Number(info.recharge_money)' :duration='3000'></countTo>
              <span class="unit">元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {
  name:"",
  components:{countTo},
  data(){
    return {
      btnList:[
        {
          name:'今日',
          type:1
        },
        {
          name:'本周',
          type:2
        },
        {
          name:'近一周',
          type:3
        },
        {
          name:'本月',
          type:4
        },
        {
          name:'近一个月',
          type:5
        },
      ],
      active:5,
      chartLoading:false,
      info: null,
      total:null,
    }
  },
  mounted(){
    this.getTotal(this.active);
  },
  methods:{
    selectType(item){
      if(this.active == item.type) return;
      this.active = item.type;
      this.getTotal(this.active);
    },
    getTotal(type){
      let param = {}
      switch(type){
        case 1:
          param.start_time = this.getTimeString(0)[0]
          param.end_time = this.getTimeString(0)[1]
        break;
        case 2:
          let today = new Date();
          param.start_time = this.getWeekTime(new Date())
          param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
        break;
        case 3:
          param.start_time = this.getTimeString()[0]
          param.end_time = this.getTimeString()[1]
        break;
        case 4:
        param.start_time = this.getMountTime().start;
        param.end_time = this.getMountTime().end;
        break;
        case 5:
          param.start_time = this.getTimeString(30)[0]
          param.end_time = this.getTimeString(30)[1]
        break;
      }
      this.chartLoading = true;
      this.$api.getV2Third(param).then(res => {
        if(res.code == 1){
          this.info = {...res.data}
        }
      }).finally(() => {
        setTimeout(()=>{
          this.chartLoading = false;
        },500)
      })
    },
    openOrder(status,name){
      // this.$emit('openOrder', {
      //   status,
      //   name,
      //   type: this.active
      // })
    },
    getTimeString(num = 7){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * num)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return [thirtyTime,todayTime]
    },
    getWeekTime(date) {
      var days=date.getDay();
      days=days==0?7:days;
        let weekFirstDay = new Date(date - (days - 1) * 86400000 );
        let firstMonth = Number(weekFirstDay.getMonth()) + 1;
        let weekFirstDays = weekFirstDay.getDate();
        return weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays;
    },
    getMountTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let startTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-01`
      return {
        end: todayTime,
        start: startTime
      }
    },
  },
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  .item{
    height: calc(50% - 6px);
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }
  .top-title{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-box{
      flex: 1;
      height: 100%;
      overflow: hidden;
      padding-left: 12px;
      box-sizing: border-box;
      .box-section{
        width: 100%;
        height: 100%;
        // text-align: right;
        white-space: nowrap;
        overflow: hidden;
        .btn{
          display: inline-block;
          height: 28px;
          background: rgba(1, 47, 107, 0.4);
          border: 1px solid #012F6B;
          font-size: 12px;
          color: #63B2FF;
          line-height: 28px;
          padding: 0 10px;
          border-radius: 2px;
          margin-left: 8px;
          cursor: pointer;
        }
        .btn-active{
          background: rgba(110, 178, 255, 0.2);
          border: 1px solid #04DCE2;
          color: #06FCFC;
          box-shadow: inset 0px 0px 14px 0px rgba(2,252,255,0.5);
        }
      }
    }
  }
  .ele-box{
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    box-sizing: border-box;
  }
  .ele{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .tag{
      width: 140px;
      height: 178px;
      background-size: 100% 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      span{
        margin-bottom: 5px;
      }
    }
    .text{
      font-size: 32px;
      line-height: 45px;
      font-weight: 600;
      margin-top: 20px;
      .unit{
        font-size: 16px;
        position: relative;
        top: -1px;
        font-weight: 500;
      }
    }
    &:nth-child(1){
      color: #FF8F31;
      .tag{
        background: url('../../assets/image/data/detail/1.png') no-repeat; 
      }
    }
    &:nth-child(2){
      color: #1FE196;
      .tag{
        background: url('../../assets/image/data/detail/2.png') no-repeat;
      }
    }
    &:nth-child(3){
      color: #D424FA;
      .tag{
        background: url('../../assets/image/data/detail/3.png') no-repeat;
      }
    }
    &:nth-child(4){
      color: #04E8F6;
      .tag{
        background: url('../../assets/image/data/detail/4.png') no-repeat;
      }
    }
  }
  .btm-box{
    width: 100%;
    height: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .btm-ele{
      box-sizing: border-box;
      width: calc(50% - 6px);
      height: calc(50% - 6px);
      margin-bottom: 12px;
      box-shadow: inset 0px 1px 117px 1px #002263;
      border: 1px solid rgba(19,68,197,0.42);
      display: flex;
      align-items: center;
      padding: 24px;
      img{
        width: 60px;
        height: 68px;
      }
      .desc{
        padding-left: 24px;
        .text{
          font-size: 18px;
          line-height: 26px;
          color: #E9EFFF;
          font-weight: 500;
        }
        .num{
          font-size: 40px;
          font-weight: 48px;
          color: #B2C7FF;
          font-weight: bold;
          margin-top: 10px;
          .unit{
            font-size: 16px;
            color: #96B1F2;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
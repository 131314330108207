<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="top-title" style="margin-bottom: 10px">
      <titles title="销售交易趋势"/>
      <div class="btn-box">
        <vue-scroll :ops="ops">
          <div class="box-section">
            <div 
              class="btn" 
              v-for="(item,index) in itemList" 
              :key="index" 
              :class="{'btn-active':index == active}"
              @click="selectType(index)"
            >{{ item.name }}</div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <div class="charts" ref="chart"></div>
    <chartDetail :show="detailShow" :title="activeTille" :id="activeId" :query="detailParam" :queryList="detailList" @close="detailShow = false"/>
  </div>
</template>
<script>
import titles from './title.vue'
import chartDetail from './chart-detail.vue'
import * as echarts from 'echarts';
export default {
  name:"",
  components: {titles, chartDetail},
  data(){
    return {
      ops:{
        scrollPanel:{
          scrollingY: false,
          scrollingX: true,
        },
      },
      chartLoading:false,
      itemList:[],
      active:0,
      detailShow: false,
      detailParam: null,
      detailList: null,
      activeTille:'',
      activeId:''
    }
  },
  mounted(){
    this.chartLoading = true;
    this.$api.getV2FifthFactory({
      customerId: this.$route.params.id
    }).then(res=>{
      if(res.code == 1){        
        this.itemList = res.data.lists.filter(ele => {
          return ele.id && ele.name
        });
        this.activeTille = this.itemList[this.active].name;
        this.activeId = this.itemList[this.active].id;
        this.getEchartData(this.active)
      }
    }).catch(err => {
      this.chartLoading = false;
    })
  },
  methods: {
    selectType(index){
      if(this.active == index) return;
      this.active = index;
      this.getEchartData(this.active)
      if (this.myChart) {//关键步骤
        this.myChart.dispose()
        this.myChart = null
      }
      const chart = this.$refs.chart;
      if (chart) {
        this.myChart = echarts.init(chart)
      }
    },
    getEchartData(index){
      this.chartLoading = true;
      this.$api.getV2Fifth({
        factory_id: this.itemList[index].id,
        start_time: this.getThirtyTime()[0],
        end_time: this.getThirtyTime()[1]
      }).then(res => {
        if(res.code == 1){
          this.setEchartData(this.sortData(res.data.lists))
        }
      }).finally(() => {
        setTimeout(() => {
          this.chartLoading = false;
        },200)
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      let new_arr = arr[0].list.map(element => {
        return {
          name: element.name,
          list: arr.map(ele => {
            let item = ele.list.find(e => {
              return e.name == element.name
            })
            return item ? Number(item.money).toFixed(0) : 0
          })
        }
      })
      let total = arr.map(ele => {
        return (ele.list.reduce((pre,cur) => {
          return pre + cur.money * 100 ;
        }, 0) / 100).toFixed(0)
      })
      if (chart) {
        this.myChart = echarts.init(chart)
        const option = {
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  arr.map(ele => {
              return ele.time ? ele.time.slice(5,10) : '未知日期'
            }),
            axisLabel:{
              color:'#B2E6FF'
            },
            axisTick:{
              show: false
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle:{
                width: 0.5,
                type:'dashed',
                color:'#1B407F',
              }
            },
            axisLabel:{
              color:'#B2E6FF'
            }
          },
          tooltip: {
            trigger: 'axis',
            formatter:(params) => {
              var res = `${params[0].name} <br/>`
              for (const item of params) {
                if (item.value > 0) {
                  res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}<br/>`
                }
              }
              return res
            }
          },
          legend: {
            data: ['总量','七日均额', '十五日均额','三十日均额'],
            textStyle:{
              color: '#ffffff'
            },
            selected:{
              '七日均额': false,
              '十五日均额':  false,
              '三十日均额': false
            }
          },
          series: [
            ...new_arr.map(ele => {
              return {
                name: ele.name,
                type: 'bar',
                stack: 'total',
                barWidth: 10,
                emphasis: {
                  focus: 'series'
                },
                data: ele.list
              }
            }),
            {
              name: '总量',
              type: 'line',
              smooth: true,
              color:'#43E9E4',
              label: {
                show: true,
                position: 'top',
                color: '#7DCEF4'
              },
              data: total
            },
            {
              name: '七日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              data: arr.map(ele => {
                return Number(ele.sevenAverageMoney) || 0
              })
            },
            {
              name: '十五日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              data: arr.map(ele => {
                return Number(ele.fifteenAverageMoney) || 0
              })
            },
            {
              name: '三十日均额',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万元';
                }
              },
              data: arr.map(ele => {
                return Number(ele.thirtyAverageMoney) || 0
              })
            }
          ],
          grid: {
            top:30,
            right: 10,
            bottom:20,
            left:60
          },
        };
        this.myChart.setOption(option);
        let _this = this;
        this.myChart.on('click', function (params) {
          _this.openDetail(params,arr)
        });
      }
    },
    sortData(list){
      let nameList = [];
      list.forEach(element => {
        element.goods_info.forEach(ele => {
          let item = nameList.find(e => {
            return e == ele.goods_name
          })
          if(!item){
            nameList.push(ele.goods_name)
          }
        })
      })
      let new_list = list.map(element => {
        return {
          time: element.date,
          sevenAverageMoney: element.day_avg_7,
          thirtyAverageMoney: element.day_avg_15,
          fifteenAverageMoney: element.day_avg_30,
          list: nameList.map(ele => {
            let eleMoney = element.goods_info.find(e => {
              return e.goods_name == ele
            });
            let money = eleMoney ? Number(eleMoney.goods_material_sum_price) : 0;
            return {
              name: ele,
              money: money
            }
          })
        }
      })
      return new_list
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return [thirtyTime,todayTime]
    },
    openDetail(params,list){
      console.log(params)
      this.activeTille = this.itemList[this.active].name;
      this.activeId = this.itemList[this.active].id;
      this.detailParam = params;
      this.detailList = list;
      this.detailShow = true;
    }
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .top-title{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-box{
      flex: 1;
      height: 100%;
      overflow: hidden;
      padding-left: 200px;
      box-sizing: border-box;
      .box-section{
        width: 100%;
        height: 100%;
        text-align: right;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        .btn{
          display: inline-block;
          height: 28px;
          background: rgba(1, 47, 107, 0.4);
          border: 1px solid #012F6B;
          font-size: 12px;
          color: #63B2FF;
          line-height: 28px;
          padding: 0 10px;
          border-radius: 2px;
          margin-left: 8px;
          cursor: pointer;
        }
        .btn-active{
          background: rgba(110, 178, 255, 0.2);
          border: 1px solid #04DCE2;
          color: #06FCFC;
          box-shadow: inset 0px 0px 14px 0px rgba(2,252,255,0.5);
        }
      }
    }
  }
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="section">
    <div class="top" v-loading="chart1Loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <div class="title-box">
        <div class="title">
          <span v-if="active == 0">今日数据</span>
          <span v-if="active == 1">本周数据</span>
          <span v-if="active == 2">本月数据</span>
          <span v-if="active == 3">近一个月数据</span>
        </div>
        <div class="btn-box">
          <div class="btn" 
            v-for="(item,index) in btnList" 
            :key="index" 
            :class="{'btn-active':item.type == active}"
            @click="selectType(item)"
          >{{ item.name }}</div>
        </div>
      </div>
      <div class="item">
        <div class="ele">
          <img src="../../assets/image/data/data-1.png" alt="">
          <div class="ele-box">
            <div class="ele-top">
              <div class="top-left">下单数量</div>
              <div class="top-right">{{ info.count }} <span>单</span></div>
            </div>
            <img class="bar" src="../../assets/image/data/data-bar-1.png">
          </div>
        </div>
        <div class="ele">
          <img src="../../assets/image/data/data-2.png" alt="">
          <div class="ele-box">
            <div class="ele-top">
              <div class="top-left">贸易总金额</div>
              <div class="top-right">{{ info.total_money }} <span>元</span></div>
            </div>
            <img class="bar" src="../../assets/image/data/data-bar-2.png">
          </div>
        </div>
        <div class="ele">
          <img src="../../assets/image/data/data-3.png" alt="">
          <div class="ele-box">
            <div class="ele-top">
              <div class="top-left">运输总金额</div>
              <div class="top-right">{{ info.freight_total_money }} <span>元</span></div>
            </div>
            <img class="bar" src="../../assets/image/data/data-bar-3.png">
          </div>
        </div>
        <div class="ele">
          <img src="../../assets/image/data/data-4.png" alt="">
          <div class="ele-box">
            <div class="ele-top">
              <div class="top-left">下单总吨数</div>
              <div class="top-right">{{ info.net_weight }} <span>吨</span></div>
            </div>
            <img class="bar" src="../../assets/image/data/data-bar-4.png">
          </div>
        </div>
      </div>
    </div>
    <div class="btm" v-loading="chart2Loading" element-loading-background="rgba(0, 0, 0, 0.3)">
      <div class="title-box">
        <div class="title">商砼销售排行</div>
        <div class="btn-box">
          <div class="btn" v-if="topCity">{{ topCity }}</div>
        </div>
      </div>
      <div class="box">
        <swiper ref="mySwiper" :options="swiperOptions" style="height:100%">
          <swiper-slide class="swiper-slide" v-for="(item,index) in topList" :key="index">
            <div class="list">
              <div class="num" :class="{num1:index == 0,num2:index == 1,num3:index == 2}">{{ index + 1 }}</div>
              <div class="name">
                {{ item.name }}
                <span>{{ (Number(item.sum_money)/10000).toFixed(2) }}万元</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name:"",
  components: {
    swiper,
    swiperSlide
  },
  data(){
    return {
      btnList:[
        {
          name:'本周',
          type:1
        },
        {
          name:'本月',
          type:2
        },
        {
          name:'近一个月',
          type:3
        },
      ],
      active:1,
      swiperOptions: {
        direction:'vertical',
        slidesPerView:5,
        autoplay:{
          delay: 2000
        },
      },
      topList:[],
      topCity:null,
      info:{
        count:0,
        total_money:0,
        freight_total_money:0,
        net_weight:0,  
      },
      chart1Loading: false,
      chart2Loading:false,
    }
  },
  mounted(){
    this.getTotal(this.active);
    this.getTopList(); 
  },
  methods:{
    selectType(item){
      if(item.type != this.active){
        this.active = item.type
      }else{
        this.active = 0
      }
      this.getTotal(this.active);
    },
    getTotal(type){
      let param = {
        type: 2
      }
      if (type == 0){
        let today = new Date();
        param.start_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
        param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
      } else if (type == 1){
        let monday = new Date();
        monday.setDate(monday.getDate() - monday.getDay() + 1)
        let today = new Date();
        param.start_time = `${monday.getFullYear()}-${(monday.getMonth() + 1)}-${monday.getDate()}`;
        param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
      } else if (type == 2){
        param.start_time = this.getMountTime().start;
        param.end_time = this.getMountTime().end;
      } else {
        param.start_time = this.getThirtyTime().thirtyTime;
        param.end_time = this.getThirtyTime().todayTime;
      }
      if(this.chart1Loading) return;
      this.chart1Loading = true;
      this.$api.getOrderTotal(param).then(res => {
        if(res.code == 1){
          this.info = {...res.data.info}
        }
      }).finally(() => {
        setTimeout(()=>{
          this.chart1Loading = false;
        },500)
      })
    },
    getTopList(){
      this.chart2Loading = true
      this.$api.getConTop({
        type:2,
        page:1,
        size:10
      }).then(res => {
        if(res.code == 1){
          this.topCity = res.data.current_city;
          this.topList = [...res.data.lists];
          setTimeout(()=>{
            this.swiperOptions.loopAdditionalSlides = 5;
            this.swiperOptions.loop = true; 
          },500) 
        }
      }).finally(() => {
        setTimeout(()=>{
          this.chart2Loading = false;
        },500)
      })
    },
    getMountTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let startTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-01`
      return {
        end: todayTime,
        start: startTime
      }
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  },
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 40px 20px;
  box-sizing: border-box;
  .top{
   width: 100%;
   flex: 1;
   display: flex;
   flex-direction: column;
   .item{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 0;
    .ele{
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        height: 100%;
      }
      .ele-box{
        flex: 1;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
        box-sizing: border-box;
        .ele-top{
          width: 100%;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 20px;
          box-sizing: border-box;
          .top-left{
            font-size: 14px;
            color: #fff;
          }
          .top-right{
            font-size: 22px;
            font-weight: 600;
            line-height: 22px;
            color: #fff;
            span{
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
        .bar{
          width: calc(100% + 24px);
          height: 20px;
          position: relative;
          left: -24px;
        }
      }
    }
   }
  }
  .btm{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    .box{
      width: 100%;
      flex: 1;
      overflow: hidden;
    }
  }
  .title-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
      width: 160px;
      height: 32px;
      background: url('../../assets/image/data/title-bg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      padding-left: 36px;
      margin-top: 10px;
    }
    .btn-box{
      height: 32px;
      display: flex;
      align-items: center;
      .btn{
        height: 28px;
        background: rgba(1, 47, 107, 0.4);
        border: 1px solid #013F86;
        font-size: 12px;
        color: #63B2FF;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 2px;
        margin-left: 8px;
        cursor: pointer;
      }
      .btn-active{
        background: rgba(0, 147, 167, 0.5);
        border: 1px solid #00BFEF;
        box-shadow: 0 0 8px 0 #0093a7 inset;
        color: #00F7FF;
      }
    }
  }
}
.swiper-slide{
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 3px 0;
  .list{
    width: 100%;
    height: 100%;
    background: rgba(2, 69, 142, 0.25);
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    .num{
      width: 22px;
      height: 22px;
      background: url('../../assets/image/data/4.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-top: 1px;
      font-size: 12px;
      color: #1782D7;
    }
    .num1{
      background: url('../../assets/image/data/1.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
    .num2{
      background: url('../../assets/image/data/2.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
    .num3{
      background: url('../../assets/image/data/3.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
    .name{
      margin-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      font-size: 16px;
      color: #07E1FF;
      span{
        color: #fff;
      }
    }
    
  }
}
</style>
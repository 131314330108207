<template>
  <div class="section" v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <titles title="商品交易数据"/>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import titles from './title.vue'
import * as echarts from 'echarts';
export default {
  name:"",
  components: {titles},
  data(){
    return {
      chartLoading: false
    }
  },
  mounted(){
    this.getEchartData();
  },
  methods: {
    getEchartData(){
      this.chartLoading = true;
      this.$api.getV2First({
        // name: this.$route.params.id
      }).then(res => {
        if(res.code == 1){
          res.data.lists.sort((a,b)=>{
            return a.total_money - b.total_money
          })
          this.setEchartData(res.data.lists.slice(0,8))
        }
      }).finally(() => {
        this.chartLoading = false;
      })
    },
    setEchartData(arr){
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = echarts.init(chart)
        let list = arr.map((ele) => {
          return (Number(ele.total_money) / 10000).toFixed(2)
        })
        const option = {
          tooltip : {
            trigger: 'axis'
          },
          grid: {
            top: 0,
            left: 0,
            right: 36,
            bottom: 0,
            containLabel: true
          },
          xAxis : [
            {
              type : 'value',
              splitLine:{
                show: false
              },
              axisLabel:{
                show:false
              },
              axisLine:{
                show: false,
              },
              axisTick:{
                show: false
              }
            }
          ],
          yAxis : [
            {
              show:true,
              type : 'category',
              position:'left',
              axisLabel:{
                color:'#B2E6FF',
              },
              axisTick:{
                show: false
              },
              axisLine:{
                show: false
              },
              data : arr.map(ele => {
                return ele.goods_name
              }),
            }
          ],
          tooltip: {
            trigger: 'axis',
            formatter:(params) => {
              let param = params[0]
              let item = arr.find(ele => {
                return ele.goods_name == param.name
              })
              let total = param.value < 0 ? -param.value : param.value
              var res = `${param.name} <br/>`
              res += `<span style="background: ${param.color.colorStops[1].color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.factory_name || '-'} ：${total}万元<br/>`
              return res
            }
          },
          series : [
            {
              type:'bar',
              barWidth: 12,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#FFB247' },
                  { offset: 1, color: '#5F71FD' }
                ]),
                borderRadius: 10
              },
              label: {
                show: true,
                position: 'right',
                color: '#ffffff'
              },
              data: list,
            }
          ]
        };
        myChart.setOption(option);
      }
    },
  }
}
</script>
<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .charts{
    width: 100%;
    flex: 1;
  }
}
</style>
<template>
  <div class="test">
    <div class="title">
      <span>环保仓小12</span>
      <div 
        class="btn" 
        v-for="(item,index) in typeList" 
        :key="index" 
        :class="{ active: active == item.type }"
        @click="setType(item)"
      >{{ item.name }}</div>
    </div>
    <div class="charts" ref="chart"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import list from '../assets/data/data.json'
console.log(list)
export default {
  name:"test",
  data(){
    return {
      myChart:null,
      typeList:[
        {
          name:'日价图',
          type:1
        },
        {
          name:'周价图',
          type:7
        },
        {
          name:'月价图',
          type:30
        }
      ],
      active:1,
      list:list,
    }
  },
  mounted(){
    const chart = this.$refs.chart;
    if (chart) {
      this.myChart = echarts.init(chart)
    }
    this.setEchartLineData(this.list)
  },
  methods:{
    setType(item){
      if(this.active == item.type) return
      this.active = item.type;
      if (this.myChart) {//关键步骤
        this.myChart.dispose()
        this.myChart = null
      }
      const chart = this.$refs.chart;
      if (chart) {
        this.myChart = echarts.init(chart)
      }
      if(this.active == 1){
        this.setEchartLineData(this.list)
      }else if(this.active == 7){
        let arr = this.list.map(ele=>{
          return {
            price: ele.price / 100,
            time: ele.time.slice(0,10),
            week: new Date(ele.time.replace(/-/g,'/')).getDay()
          }
        })
        this.setEchartWKlineData(arr)
      }else{
        let arr = this.list.map(ele=>{
          return {
            price: ele.price / 100,
            time: ele.time.slice(0,10),
            M: ele.time.slice(0,7)
          }
        })
        this.setEchartMKlineData(arr)
      }
    },
    sortClass(sortData){
      const groupBy = (array, f) => {
        let groups = {};
        array.forEach((o) => {
          let group = JSON.stringify(f(o));
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return Object.keys(groups).map((group) => {
          return groups[group];
        });
      };
      const sorted = groupBy(sortData, (item) => {
        return item.M; // 返回需要分组的对象
      });
      return sorted;
    },
    sliceWeek(data){
      let $data = [...data];
      let arr = [];
      let j = 0;
      for(let i = 0; i < data.length; i ++){
        if(data[i].week == 0){
          arr.push(data.slice(j, i + 1))
          j = i + 1;
        }
      }
      arr.push(data.slice(j, data.length))
      return arr
    },
    calculateMA(dayCount, data) {
      var result = [];
      for (var i = 0, len = data.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        var sum = 0;
        for (var j = 0; j < dayCount; j++) {
          sum += data[i - j].value[1];
        }
        result.push((sum / dayCount).toFixed(2));
      }
      return result;
    },
    setEchartLineData(arr){
      let _data = arr.map(ele=>{
        return ele.price / 100
      })
      const option = {
        backgroundColor:'#142846',
        xAxis: {
          type: 'category',
          data: arr.map(ele=>{
            return ele.time.slice(0,10)
          })
        },
        yAxis: {
          type: 'value',
          name: '单位：元',
          nameLocation: 'end',
          nameTextStyle:{
            align:'left'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [
          {
            type: "inside",//inside鼠标缩放
            show: true,
            filterMode: 'none',
            minSpan: 5
          }
        ],
        series: [
          {
            name:'环保仓小12',
            data: _data,
            type: 'line',
            showSymbol: false,
            smooth: true
          }
        ]
      };
      this.myChart.setOption(option);
    },
    setEchartWKlineData(arr){
      let Mlist = this.sliceWeek(arr);
      let new_Mlist = Mlist.map(element => {
        return {
          time: element[element.length - 1].time,
          timeRange: `${element[0].time} ~ ${element[element.length-1].time}`,
          data: element.map(ele=>{
            return ele.price
          })
        }
      })
      let option = {
        legend: {
          data: [ 'MA5', 'MA10', 'MA20']
        },
        xAxis: {
          type: 'category',
          data: new_Mlist.map(ele => {
            return ele.time
          })
        },
        yAxis: {
          type: 'value',
          name: '单位：元',
          nameLocation: 'end',
          nameTextStyle:{
            align:'left'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
        },
        dataZoom: [
          {
            type: "inside",
            show: true,
            filterMode: 'none',
            minSpan: 10
          }
        ],
        series: [
          {
            type: 'candlestick',
            name:'环保仓小12',
            data: new_Mlist.map(ele => {
              return {
                value: [ele.data[0],ele.data[ele.data.length-1],Math.min(...ele.data),Math.max(...ele.data)]
              }
            })
          },
          {
            name: 'MA5',
            type: 'line',
            data: this.calculateMA(5, new_Mlist.map(ele => {
              return {
                value: [ele.data[0],ele.data[ele.data.length-1],Math.min(...ele.data),Math.max(...ele.data)]
              }
            })),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'MA10',
            type: 'line',
            data: this.calculateMA(10, new_Mlist.map(ele => {
              return {
                value: [ele.data[0],ele.data[ele.data.length-1],Math.min(...ele.data),Math.max(...ele.data)]
              }
            })),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'MA20',
            type: 'line',
            data: this.calculateMA(20, new_Mlist.map(ele => {
              return {
                value: [ele.data[0],ele.data[ele.data.length-1],Math.min(...ele.data),Math.max(...ele.data)]
              }
            })),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
        ]
      };
      this.myChart.setOption(option);
    },
    setEchartMKlineData(arr){
      let Mlist = this.sortClass(arr);
      let new_Mlist = Mlist.map(element => {
        return {
          time: element[0].M,
          timeRange: `${element[0].time} ~ ${element[element.length-1].time}`,
          data: element.map(ele=>{
            return ele.price
          })
        }
      })
      let option = {
        xAxis: {
          type: 'category',
          data: new_Mlist.map(ele => {
            return ele.time
          })
        },
        yAxis: {
          type: 'value',
          name: '单位：元',
          nameLocation: 'end',
          nameTextStyle:{
            align:'left'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
        },
        dataZoom: [
          {
            type: "inside",
            show: true,
            filterMode: 'none',
            minSpan: 20
          }
        ],
        series: [
          {
            type: 'candlestick',
            name:'环保仓小12',
            data: new_Mlist.map(ele => {
              return {
                value: [ele.data[0],ele.data[ele.data.length-1],Math.min(...ele.data),Math.max(...ele.data)]
              }
            })
          }
        ]
      };
      this.myChart.setOption(option);
    },
  }
}
</script>
<style scoped lang='less'>
.test{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  .title{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    .btn{
      padding: 6px 12px;
      border: 1px solid #eee;
      margin-left: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #666;
      cursor: pointer;
    }
    .active{
      border: 1px solid #333;
      color: #333;
    }
  }
  .charts{
    flex: 1;
    width: 100%;
  }
}
</style>
<template>
  <div class="card">
    <div class="icon icon-1"></div>
    <div class="icon icon-2"></div>
    <div class="icon icon-3"></div>
    <div class="icon icon-4"></div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name:"card",
  data(){
    return {
       
    }
  },
}
</script>
<style scoped lang='less'>
.card{
  width: 100%;
  height: 100%;
  min-height: 50px;
  background: rgba(25, 49, 86, .85);
  position: relative;
  .icon{
    width: 14px;
    height: 18px;
    position: absolute;
  }
  .icon-1{
    left: 0;
    top: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .icon-2{
    right: 0;
    top: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      top: 0;
      right: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .icon-3{
    right: 0;
    bottom: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .icon-4{
    left: 0;
    bottom: 0;
    &::before{
      content: '';
      width: 100%;
      height: 2px;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &::after{
      content: '';
      width: 2px;
      height: 100%;
      background: #058ACB;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    wallid: null,
    userinfo: null 
  },
  mutations: {
    TOKEN(state, token){
      if(token){
        localStorage.setItem('data_token', token)
      }else{
        localStorage.removeItem('data_token')
      }
      state.token = token
    },
    WALLID(state, wallid){
      if(wallid){
        localStorage.setItem('data_wallid', wallid)
      }else{
        localStorage.removeItem('data_wallid')
      }
      state.wallid = wallid
    },
    USERINFO(state, userinfo){
      if(userinfo){
        localStorage.setItem('data_userinfo', JSON.stringify(userinfo))
      }else{
        localStorage.removeItem('data_userinfo')
      }
      state.userinfo = userinfo
    }
  },
  getters:{
    token(state) {
			return state.token || localStorage.getItem('data_token')
		},
    wallid(state) {
			return state.wallid || localStorage.getItem('data_wallid')
		},
    userinfo(state) {
      return state.userinfo || JSON.parse(localStorage.getItem('data_userinfo'))
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <vue-scroll :ops="ops" class="bg">
    <dv-full-screen-container style="position: relative">
      <div class="header">
        <div class="header-l">
          <div class="box">
            <i class="el-icon-time"></i>
            <times />
          </div>
        </div>
        <div class="header-t">矿山数据平台</div>
        <div class="header-r">
          <!-- <div class="btn" @click.stop="$router.push('/map')">地图数据</div>
          <i class="el-icon-switch-button" @click="logout"></i> -->
        </div>
      </div>
      <div class="data-section">
        <div class="center">
          <div class="center-item">
            <data-card>
              <chart1/>
            </data-card>
          </div>
          <div class="center-item">
            <data-card>
              <chart2/>
            </data-card>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-item">
            <data-card>
              <chart3/>
            </data-card>
          </div>
          <div class="bottom-item">
            <data-card>
              <chart4/>
            </data-card>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </vue-scroll>
</template>
<script>
import times from '../components/dataTimes.vue'
import dataCard from '../components/data-crad.vue'
import chart1 from '../components/chart/chart-1.vue'
import chart2 from '../components/chart/chart-2.vue'
import chart3 from '../components/chart/chart-3.vue'
import chart4 from '../components/chart/chart-4.vue'
export default {
  name:"chart",
  components:{times,dataCard,chart1,chart2,chart3,chart4},
  data(){
    return {
      ops:{
        scrollPanel:{
          scrollingX: false,
        },
      }
    }
  },
}
</script>
<style scoped lang='less'>
  .bg{
    background: url('../assets/image/data/bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .header{
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .header-l{
      width: 535px;
      height: 74px;
      background: url('../assets/image/data/top-left.png') no-repeat;
      background-size: 100% 100%;
      padding: 16px 180px 12px 16px;
      box-sizing: border-box;
      .box{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #D2E3FF;
        i{
          margin: 0 12px 0 20px;
          color: #00cadb;
          font-size: 22px;
        }
      }
      
    }
    .header-r{
      width: 535px;
      height: 74px;
      background: url('../assets/image/data/top-right.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      .btn{
        width: 88px;
        height: 38px;
        background: linear-gradient(360deg, #0054BE 0%, #20C7F4 100%);
        border-radius: 2px;
        line-height: 38px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        margin-left: 396px;
        margin-top: 20px;
      }
      i{
        margin-left: 10px;
        margin-top: 30px;
        color: #00cadb;
        font-size: 22px;
        cursor: pointer;
      }
    }
    .header-t{
      flex: 1;
      height: 100%;
      background: url('../assets/image/data/top-title.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      font-weight: bold;
      font-style: italic;
      color: #FFFFFF;
      padding-bottom: 6px;
      line-height: 84px;
    }
  }
  .data-section{
    width: 100%;
    height: calc(100% - 90px);
    box-sizing: border-box;
    padding: 10px 20px 16px;
    display: flex;
    flex-direction: column;
    .center{
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      overflow: hidden;
      .center-item{
        height: 100%;
        flex: 1;
        margin-right: 16px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .bottom{
      width: 100%;
      flex: 1;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bottom-item{
        height: 100%;
        flex: 1;
        margin-right: 16px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  </style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false

// import "swiper/swiper.min.css"

import headers from './components/headers.vue';
import card from './components/card.vue';
import infoWindow from './components/infoWindow.vue';
Vue.component('headers',headers)
Vue.component('card',card)
Vue.component('infoWindow',infoWindow)

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// 滚动条插件
import vuescroll from 'vuescroll/dist/vuescroll-native';
Vue.use(vuescroll);
Vue.prototype.$vuescrollConfig = {
  vuescroll: {
    mode: 'native',
    detectResize: true,
    wheelScrollDuration:200,
    wheelDirectionReverse:true
  },
  
  bar: {
    background: '#058ACB'
  },
  rail: {
    background: '#058ACB',
    opacity: 0.05,
    size: '6px',
    specifyBorderRadius: false,
    gutterOfEnds: '0',
    gutterOfSide: '0',
  }
};

import ElementUI from 'element-ui';       // element ui
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import api from './assets/axios/api'      // 接口管理
Vue.prototype.$api = api;

import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '69e938317d4c2beec82b0dd217984b90',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.15'
});

Vue.prototype.getWindowSize = () => {
  return {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  }
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="section"  v-loading="chartLoading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="top-title">
      <data-title title="今日数据" v-if="active == 0" :width="80"></data-title>
      <data-title title="本周数据" v-if="active == 1" :width="80"></data-title>
      <data-title title="本月数据" v-if="active == 2" :width="80"></data-title>
      <data-title title="近一个月数据" v-if="active == 3" :width="120"></data-title>
      <div class="btn-box">
        <div class="btn" 
          v-for="(item,index) in btnList" 
          :key="index" 
          :class="{'btn-active':item.type == active}"
          @click="selectType(item)"
        >{{ item.name }}</div>
      </div>
    </div>
    <div class="box">
      <div class="item">
        <div class="bar bar-1">
          <div class="text-box">
            <countTo :startVal='0' :endVal='Number(info.count)' :duration='1000'></countTo>
            <span class="unit">单</span>
          </div>
        </div>
        <span class="name">下单数量</span>
        <div class="line"></div>
      </div>
      <div class="item">
        <div class="bar bar-2">
          <div class="text-box">
            <countTo :startVal='0' :endVal='Number(info.total_money)' :duration='3000'></countTo>
            <span class="unit">元</span>
          </div>
        </div>
        <span class="name">贸易总金额</span>
        <div class="line"></div>
      </div>
      <div class="item">
        <div class="bar bar-3">
          <div class="text-box">
            <countTo :startVal='0' :endVal='Number(info.freight_total_money)' :duration='3000'></countTo>
            <span class="unit">元</span>
          </div>
        </div>
        <span class="name">运输总金额</span>
        <div class="line"></div>
      </div>
      <div class="item">
        <div class="bar bar-4">
          <div class="text-box">
            <countTo :startVal='0' :endVal='Number(info.net_weight)' :duration='2000' :decimals="2"></countTo>
            <span class="unit">吨</span>
          </div>
        </div>
        <span class="name">下单总吨数</span>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTitle from '../../components/data-title.vue'
import countTo from 'vue-count-to';
export default {
  name:"data-card-2",
  components:{dataTitle,countTo},
  data(){
    return {
      btnList:[
        {
          name:'今日',
          type:0
        },
        {
          name:'本周',
          type:1
        },
        {
          name:'本月',
          type:2
        },
        {
          name:'近一个月',
          type:3
        },
      ],
      active:1,
      chartLoading:false,
      info:{
        count:0,
        total_money:0,
        freight_total_money:0,
        net_weight:0,  
      },
    }
  },
  mounted(){
    this.getTotal(this.active);
  },
  methods:{
    selectType(item){
      if(this.active == item.type) return
      this.active = item.type
      this.getTotal(this.active);      
    },
    getTotal(type){
      let param = {
        type: 2
      }
      if (type == 0){
        let today = new Date();
        param.start_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
        param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
      } else if (type == 1){
        // let monday = new Date(new Date().toLocaleDateString()).getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000;
        // monday = new Date(monday)

        // console.log()
        // monday.setDate(monday.getDate() - monday.getDay() + 1)
        // console.log()
        let today = new Date();
        param.start_time = this.getCurrentWeekFirstDay(new Date());
        param.end_time = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
      } else if (type == 2){
        param.start_time = this.getMountTime().start;
        param.end_time = this.getMountTime().end;
      } else {
        param.start_time = this.getThirtyTime().thirtyTime;
        param.end_time = this.getThirtyTime().todayTime;
      }
      if(this.chartLoading) return;
      this.chartLoading = true;
      this.$api.getOrderTotal(param).then(res => {
        if(res.code == 1){
          this.info = {...res.data.info}
        }
      }).finally(() => {
        setTimeout(()=>{
          this.chartLoading = false;
        },500)
      })
    },
    getCurrentWeekFirstDay(date) {
      var days=date.getDay();
      days=days==0?7:days;
      
        let weekFirstDay = new Date(date - (days - 1) * 86400000 );
        //console.log('===', weekFirstDay);
        let firstMonth = Number(weekFirstDay.getMonth()) + 1;
        // if (firstMonth < 10) {
        //     firstMonth = '0' + firstMonth;
        // }
        let weekFirstDays = weekFirstDay.getDate();
        // if (weekFirstDays < 10) {
        //     weekFirstDays = '0' + weekFirstDays;
        // }
        return weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays;
    },
    getMountTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let startTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-01`
      return {
        end: todayTime,
        start: startTime
      }
    },
    getThirtyTime(){
      let today = new Date();
      let todayTime = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let todayTamp = today.getTime();
      let thirty = new Date(todayTamp - 1000 * 60 * 60 * 24 * 30)
      let thirtyTime = `${thirty.getFullYear()}-${(thirty.getMonth() + 1)}-${thirty.getDate()}`
      return {
        todayTime,
        thirtyTime
      }
    }
  }
}
</script>

<style scoped lang='less'>
.section{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-title{
    width: 420px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-box{
      height: 30px;
      display: flex;
      align-items: center;
      .btn{
        height: 28px;
        background: rgba(1, 47, 107, 0.4);
        border: 1px solid #012F6B;
        font-size: 12px;
        color: #63B2FF;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 2px;
        margin-left: 8px;
        cursor: pointer;
      }
      .btn-active{
        background: rgba(110, 178, 255, 0.2);
        border: 1px solid #6EB2FF;
        // box-shadow: 0 0 8px 0 #0093a7 inset;
        color: #6EB2FF;
      }
    }
  }
  .box{
     box-sizing: border-box;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 40px;
    .item{
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      .bar{
        width: 190px;
        height: 98px;
        position: relative;
        .text-box{
          width: 150px;
          height: 38px;
          margin: 60px auto 0;
          text-align: center;
          font-size: 28px !important;
          line-height: 38px;
          font-weight: 600;
          .unit{
            font-size: 18px;
            font-weight: 500;
            position: relative;
            top: -1px;
          }
        }
      }
      .name{
        font-size: 18px;
        margin-top: 10px;
      }
      .line{
        width: 110px;
        height: 4px;
        margin-top: 6px;
        position: relative;
      }
      &:nth-child(1){
        .bar{
          background: url('../../assets/image/data/new-data-2.png') no-repeat;
          background-size: 100% 100%;
          color: #43E9E4;
        }
        .name{
          color: #43E9E4;
        }
        .line{
          background-color: #43E9E4;
          &::before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #43E9E4;
            position: absolute;
            left: -4px;
            top: -4px;
          }
          &::after{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #43E9E4;
            position: absolute;
            right: -4px;
            top: -4px;
          }
        }
      }
      &:nth-child(2){
        .bar{
          background: url('../../assets/image/data/new-data-1.png') no-repeat;
          background-size: 100% 100%;
          color: #03A0EB;
        }
        .name{
          color: #03A0EB;
        }
        .line{
          background-color: #03A0EB;
          &::before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #03A0EB;
            position: absolute;
            left: -4px;
            top: -4px;
          }
          &::after{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #03A0EB;
            position: absolute;
            right: -4px;
            top: -4px;
          }
        }
      }
      &:nth-child(3){
        .bar{
          background: url('../../assets/image/data/new-data-3.png') no-repeat;
          background-size: 100% 100%;
          color: #FCCE58;
        }
        .name{
          color: #FCCE58;
        }
        .line{
          background-color: #FCCE58;
          &::before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #FCCE58;
            position: absolute;
            left: -4px;
            top: -4px;
          }
          &::after{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #FCCE58;
            position: absolute;
            right: -4px;
            top: -4px;
          }
        }
      }
      &:nth-child(4){
        .bar{
          background: url('../../assets/image/data/new-data-4.png') no-repeat;
          background-size: 100% 100%;
          color: #66D794;
        }
        .name{
          color: #66D794;
        }
        .line{
          background-color: #66D794;
          &::before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #66D794;
            position: absolute;
            left: -4px;
            top: -4px;
          }
          &::after{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-bottom-color: #66D794;
            position: absolute;
            right: -4px;
            top: -4px;
          }
        }
      }
    }
  }
}
</style>
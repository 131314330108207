<template>
  <vue-scroll :ops="ops" class="bg">
    <dv-full-screen-container style="position: relative">
      <div class="header">
        <div class="header-l">
          <div class="box">
            <i class="el-icon-time"></i>
            <times />
          </div>
        </div>
        <div class="header-t">{{ title }}</div>
        <div class="header-r">
          <div class="btn" @click.stop="logout">
            {{ $store.getters.wallid ? '返回' : '退出' }}
          </div>
        </div>
      </div>
      <div class="data-section">
        <div class="center">
          <div class="center-item">
            <div class="center-ele">
              <data-card  :isBorder="true">
                <detail1/>
              </data-card>
            </div>
            <div class="center-ele">
              <data-card :isBorder="true">
                <detail2/>
              </data-card>
            </div>
          </div>
          <div class="center-box">
            <detail3 @openOrder="openOrder"/>
          </div>
          <div class="center-item">
            <data-card  :isBorder="true">
              <detail4/>
            </data-card>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-item">
            <data-card  :isBorder="true">
              <detail5/>
            </data-card>
          </div>
          <div class="bottom-item">
            <data-card  :isBorder="true">
              <detail6/>
            </data-card>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
    <transition name="slide-fade">
      <div class="order-list" v-show="showOrder" @click.stop v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)">
        <data-card :isBorder="true">
          <div class="title">{{ orderName }} 订单统计</div>
          <div class="list-box" v-if="list.length > 0">
            <div class="list" v-for="(item,index) in list" :key="index">
              <div class="no">订单编号：{{ item.orderNo || '-' }}</div>
              <div class="desc">
                <div class="ele">商品名称：{{ item.goodsName || '-' }}</div>
                <div class="ele">商品单价：元</div>
                <div class="ele">总吨数：{{ item.weight || '-' }}吨</div>
                <div class="ele">总价格：{{ item.money || '-' }}元</div>
                <div class="item">起始地：{{ item.from || '-' }}</div>
                <div class="item">目的地：{{ item.to || '-' }}</div>
              </div>
              <div class="btm">
                <span class="time">{{ item.cutOffTime || '-' }}</span>
                <!-- <div class="btn" @click="openDetail(item)">详情</div> -->
              </div>
            </div>
            <div class="more" v-if="list && total > list.length" @click="getMore">
              <span v-if="!pageLoad">加载更多</span>
              <span v-else><i class="el-icon-loading"></i> 加载中</span>
            </div>          
          </div>
          <div class="list-no" v-else>暂无订单</div>
        </data-card>
      </div>
    </transition>
  </vue-scroll>
</template>
<script>
import times from '../components/dataTimes.vue'
import dataCard from '../components/data-crad.vue'
import detail1 from '../components/detail/detail-1.vue'
import detail2 from '../components/detail/detail-2.vue'
import detail3 from '../components/detail/detail-3.vue'
import detail4 from '../components/detail/detail-4.vue'
import detail5 from '../components/detail/detail-5.vue'
import detail6 from '../components/detail/detail-6.vue'
export default {
  name:"detail",
  components: {times, dataCard, detail1, detail2, detail3, detail4, detail5, detail6},
  data(){
    return {
      ops:{
        scrollPanel:{
          scrollingX: false,
        },
      },
      showOrder: false,
      loading: false,
      pageLoad: false,
      orderName: '',
      query:null,
      page:1,
      total:0,
      list: [],
      title:'',
    }
  },
  mounted(){
    this.getInfo()
    document.onclick = () => {
      this.showOrder = false;
    }
  },
  methods:{
    getInfo(){
      this.$api.getV2Info().then(res => {
        if(res.code == 1){
          this.title = res.data.name
        }
      })
    },
    logout(){
      if(this.$store.getters.wallid){
        this.$router.go(-1)
      }else{
        this.$store.commit('TOKEN',null)
        this.$store.commit('WALLID',null)
        this.$store.commit('USERINFO',null)
        this.$router.replace('/login')
      }
    },
    openOrder(data){
      this.query = data;
      this.orderName = data.name
      this.showOrder = true;
      this.page = 1;
      this.getList(this.query);
    },
    getMore(){
      this.page ++;
      this.getList(this.query);
    },
    getList(data){
      this.loading = true;
      this.pageLoad = true;
      this.$api.getDetailCustomerOrderList({
        name: this.$route.query.name,
        status: data.status,
        type: data.type,
        page: this.page,
        size: 10
      }).then(res => {
        if(this.page == 1) this.list = [];
        this.total = res.count;
        this.list.push(...res.list)
      }).finally(()=>{
        setTimeout(() => {
          this.loading = false;
          this.pageLoad = false;
        }, 200)
      })
    }
  },
}
</script>
<style scoped lang='less'>
.bg{
  background: url('../assets/image/data/bg.png') no-repeat;
  background-size: 100% 100%;
}
.header{
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .header-l{
    width: 535px;
    height: 74px;
    background: url('../assets/image/data/top-left.png') no-repeat;
    background-size: 100% 100%;
    padding: 16px 180px 12px 16px;
    box-sizing: border-box;
    .box{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #D2E3FF;
      i{
        margin: 0 12px 0 20px;
        color: #00cadb;
        font-size: 22px;
      }
      .map-btn{
        width: 40px;
        height: 50px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    
  }
  .header-r{
    width: 535px;
    height: 74px;
    background: url('../assets/image/data/top-right.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    .btn{
      width: 88px;
      height: 38px;
      background: linear-gradient(360deg, #0054BE 0%, #20C7F4 100%);
      border-radius: 2px;
      line-height: 38px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
      margin-left: 415px;
      margin-top: 20px;
    }
    i{
      margin-left: 10px;
      margin-top: 30px;
      color: #00cadb;
      font-size: 22px;
      cursor: pointer;
    }
  }
  .header-t{
    flex: 1;
    height: 100%;
    background: url('../assets/image/data/top-title.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    font-style: italic;
    color: #FFFFFF;
    padding-bottom: 6px;
    line-height: 84px;
  }
}
.data-section{
  width: 100%;
  height: calc(100% - 90px);
  box-sizing: border-box;
  padding: 10px 20px 16px;
  display: flex;
  flex-direction: column;
  .center{
    width: 100%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    .center-item{
      width: 28%;
      height: 100%;
      .center-ele{
        height: calc(50% - 6px);
        width: 100%;
        margin-bottom: 12px;
      }
    }
    .center-box{
      flex: 1;
      height: 100%;
      overflow: hidden;
      

    }
  }
  .bottom{
    width: 100%;
    min-height: 32%;
    max-height: 326px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bottom-item{
      height: 100%;
      flex: 1;
      margin-right: 16px;
      overflow-x: hidden;
      overflow-y: auto;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.order-list{
  width: 380px;
  height: calc(100% - 90px - 26px);
  min-height: 500px;
  background: rgba(43, 86, 153, 0.85);
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1000;
  .title{
    width: 100%;
    height: 32px;
    font-size: 18px;
    text-align: center;
    color: #b8e5fa;
    font-weight: 700;
  }
  .list-box{
    width: 100%;
    height: calc(100% - 34px);
    overflow-y: auto;
    &::-webkit-scrollbar { 
      display: none;
    }
    .list{
      box-sizing: border-box;
      width: 100%;
      min-height: 200px;
      margin-bottom: 12px;
      background: #023a70;
      border: 2px solid #0184bf;
      padding: 0 12px;
      .no{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        line-height: 38px;
        text-align: center;
      }
      .desc{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #214c6e;
        border-bottom: 1px solid #214c6e;
        padding: 5px 0;
        .ele{
          width: 50%;
          margin: 5px 0;
          font-size: 12px;
          color: #fff;
        }
        .item{
          width: 100%;
          margin: 7px 0;
          font-size: 12px;
          color: #fff;
        }
      }
      .btm{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time{
          font-size: 12px;
          color: #fff;
        }
        .btn{
          height: 24px;
          padding: 0 12px;
          border-radius: 12px;
          line-height: 24px;
          background: #025bcb;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .more{
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #b8e5fa;
      cursor: pointer;
    }
  }
  .list-no{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #b8e5fa;
  }
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<template>
  <div class="container">
    <dv-border-box-8 :reverse="true" class="main">
      <div class="title">登录</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="account">
          <el-input v-model="form.account" placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" show-password placeholder="请输入密码" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" :loading="loading" style="width: 100%">登录</el-button>
        </el-form-item>
      </el-form>
    </dv-border-box-8>
  </div>
</template>
<script>
// import { encode, decode } from 'js-base64';
export default {
  name:"logins",
  data(){
    return {
       form:{
         account:'',
         password:'',
       },
       loading:false,
       rules:{
          account: [
            { required: true, message: '请输入账号', trigger: 'change' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'change' }
          ],
       }
    }
  },
  mounted(){
    this.$store.commit('TOKEN',null)
    this.$store.commit('WALLID',null)
    this.$store.commit('USERINFO',null)
  },
  methods:{
    login(){
      this.$refs['form'].validate((valid) => {
        if(valid){
          if(this.form.account == 'huoxingqianli' && this.form.password == 'admin'){
            this.$notify.success({
              title: '进入总后台'
            })
            this.$router.replace('/index')
            return
          }
          this.loading = true
          this.$api.login(this.form).then(res => {
            this.$notify.success({
              title: res.msg
            })
            this.$store.commit('TOKEN',res.data.token)
            this.$store.commit('USERINFO',res.data.userinfo)
            this.$router.replace('/detail')
          }).finally(() => {
            this.loading = false
          })
          // let code = `Basic ${encode(`${this.form.account}:${this.form.password}`)}`
          // this.$store.commit('USERNAME',this.form.account)
          // this.$store.commit('TOKEN',code)
          // this.$router.replace('/index')
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
   
</style>
<style lang="less">
.container{
  background: rgb(240, 244, 248);
  width: 100%;
  min-height: 100%;
  background: #030409;
  .main{
    width: 400px;
    padding: 24px;
    height: 240px;
    border-radius: 6px;
    position: absolute;
    right: calc(50% - (400px / 2));
    top: calc(50% - (400px / 2));
    .title{
      width: 100%;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
.container /deep/.el-input{
  background: none;
}
// .container .el-button--primary{
//   background: #235fa7 !important;
//   border-color: #235fa7 !important;
// }
input{
  background: none !important;
}
</style>